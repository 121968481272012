const template = document.createElement('template');
template.innerHTML = `
  <div class="counter">
    <button type="button" class="counter__button counter__button--decrease hoverable" aria-label="Убавить">-</button>
    <input type="text" value="" class="counter__input" readonly aria-label="Количество">
    <button type="button" class="counter__button counter__button--increase hoverable" aria-label="Добавить">+</button>
  </div>
`;

const colorThemes = ['primary', 'secondary'];

customElements.define(
	'counter-component',
	class extends HTMLElement {
		#initialized = false;
		#decreaseButton = null;
		#increaseButton = null;
		#inputElement = null;

		static get observedAttributes() {
			return ['color-theme', 'name', 'value', 'step', 'min', 'max'];
		}

		get colorTheme() {
			return this.getAttribute('color-theme');
		}

		set colorTheme(newValue) {
			const colorTheme = colorThemes.includes(newValue)
				? newValue
				: colorThemes[0];

			this.setAttribute('color-theme', colorTheme);
		}

		get name() {
			return this.getAttribute('name');
		}

		set name(newValue) {
			if (newValue) {
				this.setAttribute('name', newValue);
			} else {
				this.removeAttribute('name');
			}
		}

		get value() {
			return !isNaN(parseInt(this.getAttribute('value')))
				? parseInt(this.getAttribute('value'))
				: 1;
		}

		set value(newValue) {
			if (typeof newValue === 'number' || parseInt(newValue)) {
				this.setAttribute('value', newValue);
			} else {
				this.removeAttribute('value');
			}
		}

		get step() {
			return !isNaN(parseInt(this.getAttribute('step')))
        ? parseInt(this.getAttribute('step'))
        : 1;
		}

		set step(newValue) {
			if (newValue) {
				this.setAttribute('step', newValue);
			} else {
				this.removeAttribute('step');
			}
		}

		get min() {
			return !isNaN(parseInt(this.getAttribute('min')))
        ? parseInt(this.getAttribute('min'))
        : 1;
		}

		set min(newValue) {
			if (newValue) {
				this.setAttribute('min', newValue);
			} else {
				this.removeAttribute('min');
			}
		}

		get max() {
			return !isNaN(parseInt(this.getAttribute('max')))
        ? parseInt(this.getAttribute('max'))
        : 100;
		}

		set max(newValue) {
			if (newValue) {
				this.setAttribute('max', newValue);
			} else {
				this.removeAttribute('max');
			}
		}

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();

				this.#decreaseButton.addEventListener('click', this.#decrease);
				this.#increaseButton.addEventListener('click', this.#increase);
			}

			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#decreaseButton.removeEventListener('click', this.#decrease);
			this.#increaseButton.removeEventListener('click', this.#increase);
		}

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (!this.#initialized || oldValue === newValue) {
				return;
			}

			switch (attributeName) {
				case 'color-theme': {
					this.#updateColorTheme();

					break;
				}

				case 'name': {
					this.#updateInputName();

					break;
				}

				case 'value': {
					this.#updateInputValue();
					this.#updateButtonStatus();

					break;
				}

				case 'min': {
					if (newValue > this.value) {
						this.value = newValue;
					}

					break;
				}

				case 'max': {
					if (newValue < this.value) {
						this.value = newValue;
					}

					break;
				}

				default: {
					console.warn(
						'Необработанное изменение атрибута',
						attributeName,
						oldValue,
						newValue
					);

					break;
				}
			}
		}

		#initialize() {
			this.appendChild(template.content.cloneNode(true));

			this.#decreaseButton = this.querySelector('.counter__button--decrease');
			this.#increaseButton = this.querySelector('.counter__button--increase');
			this.#inputElement = this.querySelector('.counter__input');

			if (this.value < this.min) {
				this.value = this.min;
			} else if (this.value > this.max) {
				this.value = this.max;
			}

			this.#updateColorTheme();
			this.#updateInputName();
			this.#updateInputValue();
			this.#updateButtonStatus();
		}

		#updateColorTheme() {
			if (this.colorTheme === 'secondary') {
				this.querySelector('.counter').classList.add('counter--secondary');
			} else {
				this.querySelector('.counter').classList.remove('counter--secondary');
			}
		}

		#updateInputName() {
			this.#inputElement.name = this.name;
		}

		#updateInputValue() {
			this.#inputElement.value = this.value;
		}

		#decrease = () => {
			if (this.value - this.step < this.min) return;

			this.value = this.value - this.step;
			this.#fireEvent();
		};

		#increase = () => {
			if (this.value + this.step > this.max) return;

			this.value = this.value + this.step;
			this.#fireEvent();
		};

		#updateButtonStatus() {
			this.#decreaseButton.disabled = this.value - this.step < this.min;
			this.#increaseButton.disabled = this.value + this.step > this.max;
		}

		#fireEvent() {
			const event = new CustomEvent('change', {
				detail: {
					value: this.value
				},
				bubbles: true,
				cancelable: true,
				composed: true
			});

			this.dispatchEvent(event);
		}
	}
);
