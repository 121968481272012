import axios from 'axios';

const createTemplate = (product = {}) => {
	const template = document.createElement('template');
	const domain = document.querySelector('base').getAttribute('href');
	const cuisine_color = product.cuisine_color
		? `style="--cuisine-color: ${product.cuisine_color}"`
		: '';

	template.innerHTML = `
      <div class="cart-product" ${cuisine_color}>
          <div class="cart-product__content-container">
              <div class="cart-product__information-container">
                  <h3 class="title title--small cart-product__name">${product.name}</h3>
                  ${product.attributes ? `<p class="cart-product__attributes">${product.attributes}</p>` : ''}
              </div>
              <form action="${domain}index.php?route=checkout/cart/edit" method="POST" class="cart-product__edit-form">
                  <input type="hidden" name="cart_id" value="${product.cart_id}">
                  <counter-component name="quantity" color-theme="secondary" min="0" value="${product.quantity}" class="cart-product__quantity-input"></counter-component>
                  <p class="cart-product__price">${product.total}</p>
              </form>
              <form action="${domain}index.php?route=checkout/cart/remove" method="POST" class="cart-product__remove-form">
                  <input type="hidden" name="cart_id" value="${product.cart_id}">
                  <button type="submit" class="cart-product__remove-button hoverable"
                          aria-label="Убрать из корзины">
                      <svg width="24" height="24" class="cart-product__remove-icon" aria-hidden="true">
                          <use xlink:href="catalog/view/theme/default/assets/dist/images/shared/sprite.svg#cross"></use>
                      </svg>
                  </button>
              </form>
          </div>
          <div class="cart-product__image-container decorative-container decorative-container--small decorative-container--with-border">
              <div class="decorative-container__content">
                  <img src="${product.thumb?.src}" width="${product.thumb?.width}" height="${product.thumb?.height}" loading="lazy" alt="" class="cart-product__image">
              </div>
          </div>
      </div>
    `;
	return template;
};

customElements.define(
	'cart-product',
	class extends HTMLElement {
		#initialized = false;
		#editFormElement = null;
		#removeFormElement = null;
		#editTimer = null;

		static get observedAttributes() {
			return ['product'];
		}

		get product() {
			try {
				return this.getAttribute('product')
					? JSON.parse(this.getAttribute('product'))
					: {};
			} catch (error) {
				return {};
			}
		}

		set product(newValue) {
			if (newValue) {
				this.setAttribute('product', JSON.stringify(newValue));
			} else {
				this.removeAttribute('product');
			}
		}

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();
				this.#subscribeEvents();
			}

			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#unsubscribeEvents();
		}

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (!this.#initialized || oldValue === newValue) {
				return;
			}

			switch (attributeName) {
				case 'product': {
					this.#unsubscribeEvents();
					this.#render();
					this.#subscribeEvents();

					break;
				}

				default: {
					console.warn(
						'Необработанное изменение атрибута',
						attributeName,
						oldValue,
						newValue
					);

					break;
				}
			}
		}

		#initialize() {
			this.#render();
		}

		#render() {
			this.innerHTML = '';

			if (Object.keys(this.product).length) {
				const template = createTemplate(this.product);
				this.appendChild(template.content.cloneNode(true));

				this.#editFormElement = this.querySelector('.cart-product__edit-form');
				this.#removeFormElement = this.querySelector(
					'.cart-product__remove-form'
				);
			}
		}

		#subscribeEvents = () => {
			if (this.#editFormElement) {
				this.#editFormElement.addEventListener('submit', this.#handleSubmit);
				this.#editFormElement.addEventListener('change', this.#handleChange);
			}

			if (this.#removeFormElement) {
				this.#removeFormElement.addEventListener('submit', this.#handleSubmit);
			}
		};

		#unsubscribeEvents = () => {
			if (this.#editFormElement) {
				this.#editFormElement.removeEventListener('submit', this.#handleSubmit);
				this.#editFormElement.removeEventListener('change', this.#handleChange);
			}

			if (this.#removeFormElement) {
				this.#removeFormElement.removeEventListener(
					'submit',
					this.#handleSubmit
				);
			}
		};

		#handleSubmit = async event => {
			event.preventDefault();

			try {
				const formData = new FormData(event.target);

				const response = await axios.post(event.target.action, formData);

				if (!response.data.error) {
					this.#fireEvent();
				}
			} catch (error) {
				console.error(error);
			}
		};

		#handleChange = async () => {
			if (this.#editTimer !== null) {
				clearTimeout(this.#editTimer);
				this.#editTimer = null;
			}

			this.#editTimer = setTimeout(() => {
				this.#editFormElement.requestSubmit();
			}, 300);
		};

		#fireEvent() {
			const event = new CustomEvent('cartChange', {
				bubbles: true,
				cancelable: true,
				composed: true
			});

			this.dispatchEvent(event);
		}
	}
);
