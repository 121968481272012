import axios from 'axios';

let domain = '/';
const baseElement = document.querySelector('base');

if (baseElement) {
	domain = baseElement.href ?? domain;
}

const template = document.createElement('template');
template.innerHTML = `
<form action="${domain}index.php?route=extension/total/reward/reward" method="POST" class="reward-form">
    <input type="text" name="reward" class="text-input reward-form--text-input" aria-label="Введите количество используемых бонусов">
    <div class="range-input reward-form--range-input">
        <input type="range" name="reward" class="range-input__input" aria-label="Выберите количество используемых бонусов">
    </div>
    <p class="reward-form__hint">бонусами можно оплатить до <span>50%</span> от заказа</p>
</form>
`;

class RewardForm extends HTMLElement {
	static instances = [];

	#initialized = false;
	#formElement = null;
	#textInputElement = null;
	#rangeInputElement = null;

	static get observedAttributes() {
		return ['points', 'reward'];
	}

	get points() {
		return !isNaN(parseInt(this.getAttribute('points')))
			? parseInt(this.getAttribute('points'))
			: 0;
	}

	set points(newValue) {
		if (newValue) {
			this.setAttribute('points', newValue);
		} else {
			this.removeAttribute('points');
		}
	}

	get reward() {
		return !isNaN(parseInt(this.getAttribute('reward')))
			? parseInt(this.getAttribute('reward'))
			: 0;
	}

	set reward(newValue) {
		if (newValue) {
			this.setAttribute('reward', newValue);
		} else {
			this.removeAttribute('reward');
		}
	}

	connectedCallback() {
		if (!this.#initialized) {
			this.#initialize();

			this.#formElement.addEventListener('submit', this.#handleSubmit);
			this.#formElement.addEventListener('change', this.#handleChange);

			if (!RewardForm.instances.length) {
				document.addEventListener('cartChange', RewardForm.handleCartChange);
			}

			RewardForm.instances.push(this);
		}

		this.#initialized = true;
	}

	disconnectedCallback() {
		this.#formElement.removeEventListener('submit', this.#handleSubmit);
		this.#formElement.removeEventListener('change', this.#handleChange);

		const index = RewardForm.instances.indexOf(this);

		if (index !== -1) {
			RewardForm.instances.splice(index, 1);
		}

		if (!RewardForm.instances.length) {
			document.removeEventListener('cartChange', RewardForm.handleCartChange);
		}
	}

	attributeChangedCallback(attributeName, oldValue, newValue) {
		if (!this.#initialized || oldValue === newValue) {
			return;
		}

		switch (attributeName) {
			case 'points': {
				this.#updateRangeInput();

				break;
			}
			case 'reward': {
				this.#updateInputValues();

				break;
			}

			default: {
				console.warn(
					'Необработанное изменение атрибута',
					attributeName,
					oldValue,
					newValue
				);

				break;
			}
		}
	}

	#initialize() {
		this.appendChild(template.content.cloneNode(true));

		this.#formElement = this.querySelector('form');
		this.#textInputElement = this.querySelector('input[type="text"]');
		this.#rangeInputElement = this.querySelector('input[type="range"]');

		this.#updateRangeInput();
		this.#updateInputValues();
	}

	#updateInputValues() {
		this.#textInputElement.value = this.reward;
		this.#rangeInputElement.value = this.reward;
	}

	#updateRangeInput() {
		this.#rangeInputElement.parentElement.dataset.min = '0';
		this.#rangeInputElement.parentElement.dataset.max = this.points.toString();
		this.#rangeInputElement.min = 0;
		this.#rangeInputElement.max = this.points;
	}

	#handleSubmit = async event => {
		event.preventDefault();
	};

	#handleChange = async event => {
		this.reward =
			event.target.value >= this.points ? this.points : event.target.value;

		try {
			const formData = new FormData(this.#formElement);

			const response = await axios.post(this.#formElement.action, formData);

			if (response.data.error) {
				document.querySelector('alert-dialog').showWarning(response.data.error)
			} else {
				this.reward = response.data.data.reward;

				this.#fireEvent();
			}
		} catch (error) {
			console.error(error);
		}
	};

	#fireEvent() {
		const event = new CustomEvent('totalsChange', {
			bubbles: true,
			cancelable: true,
			composed: true
		});

		this.dispatchEvent(event);
	}

	static handleCartChange = async event => {
		try {
			const response = await axios.get(
				`${domain}index.php?route=extension/total/reward/points`
			);

			if (response.data.data?.points !== undefined) {
				RewardForm.instances.forEach(instance => {
					instance.points = response.data.data?.points;
				});
			}
		} catch (error) {
			console.error(error);
		}
	};
}

customElements.define('reward-form', RewardForm);
