import IMask from 'imask';
import axios from "axios";
customElements.define(
	'account-profile',
	class extends HTMLElement {
        #initialized = false;
        #fields={};
        #mask;
		#form;
		#formEdit;
		#status=false
		connectedCallback(){
			// this.attachShadow({mode: 'open'});
			if (!this.#initialized) {
				this.#initialize();
			}
			this.#initialized = true;
		};
		#initialize = () => {
			this.#fields = {
				name: {
					input: this.querySelector('[name=name]'),
					err: this.querySelector('.err__name'),
					label: this.querySelector('[for="order-form__name"]')
				},
				phone: {
					input: this.querySelector('[name=phone]'),
					err: this.querySelector('.err__phone'),
					label: this.querySelector('[for="order-form__phone"]')
				},
				birthday: {
					input: this.querySelector('[name=birthday]'),
					err: this.querySelector('.err__birthday'),
					label: this.querySelector('[for="order-form__birthday"]')
				},
				email: {
					input: this.querySelector('[name=email]'),
					err: this.querySelector('.err__email'),
					label: this.querySelector('[for="order-form__email"]')
				}
			};
            this.#mask = IMask(this.#fields.phone.input, {
                mask: '+{7} (000) 000 0000'
            });
			this.#formEdit = this.querySelector('.accountProfile__form__formEdit')
			this.#form = this.querySelector('form');


			this.#subscribeEvents()
		};
		#subscribeEvents = () => {
			this.#formEdit.addEventListener('click',this.#handleFormEditClick)
			this.#form.addEventListener('change',this.#handleFormSubmit)
			this.#form.addEventListener('submit',this.#handleFormSubmit)
		};
		#handleFormSubmit= async ()=>{
			const formData = new FormData(this.#form)
			const response = await axios.post('/index.php?route=account/account/saveProfile',formData)
			if (response.data.errors && Object.keys(response.data.errors).length) {
				for (const key in response.data.errors) {
					if (key === 'warning') {
						document.querySelector('alert-dialog').showWarning(response.data.errors[key])
					}else{
						this.#fields[key].input.classList.add('text-input--secondary');
						this.#fields[key].label.classList.add('form__label--accent');
						this.#fields[key].err.innerText = response.data.errors[key];
					}
				}
			} else {
				// location.reload();
			}

		}
		#handleFormEditClick=event=>{
			if (!this.#status){
				for (const key in this.#fields){
					this.#fields[key].input.disabled=false;
				}
			}else{
				for (const key in this.#fields){
					this.#fields[key].input.disabled=true;
				}
			}
			this.#status=!this.#status
		}
		disconnectedCallback(){};
	}
);
