import {generateGradient} from "../../helpers";

customElements.define('alert-dialog',class extends HTMLElement{
#initialized = false;
#dialog=false
#contentError = false;
#backDropStyle = document.createElement('style');
connectedCallback(){
    if (!this.#initialized){
        this.#initialize();
        this.#subscribeEvents();
        // this.showWarning(' Текст ошибки большой длинный Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis consequuntur debitis dolorem laborum magni neque non pariatur, placeat, provident quibusdam quos rem soluta velit. Ad eligendi magnam molestias necessitatibus repellat.')
    }
}
#initialize(){
    this.#initialized=true;
    this.#dialog = this.querySelector('dialog#warning')
    this.#contentError = this.querySelector('.contentError');

}


disconnectedCallback() {

        this.#unsubscribeEvents();
    }

#subscribeEvents(){
    this.#dialog.addEventListener('click', this.#handleCloseDialog);
}
#unsubscribeEvents=()=>{
    this.#dialog.removeEventListener('click', this.#handleCloseDialog);

}
showWarning(text=''){
    this.#contentError.innerText=''
    this.#contentError.innerText=text
    this.#backDropStyle.textContent = `dialog::backdrop {
                                                 background-image: ${generateGradient()};
                                               }`;
    this.#dialog.append(this.#backDropStyle);
    this.#dialog.showModal();
}
    #handleCloseDialog =  (event) => {
        const dialogDimensions = this.#dialog.getBoundingClientRect();
        if (
            event.clientX < dialogDimensions.left ||
            event.clientX > dialogDimensions.right ||
            event.clientY < dialogDimensions.top ||
            event.clientY > dialogDimensions.bottom ||
            event.target.closest('.dialog__close-button')
        ) {
            this.#backDropStyle.remove();
            this.#dialog.close();
        }
    };

})