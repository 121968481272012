customElements.define(
	'active-link-highlighter',
	class extends HTMLElement {
		#initialized = false;
		#observer = null;
		#containerElement = null;
		#linkElements = [];
		#hamburger_linkElements = [];
    #needScrolling = true;
    #timerId = null;

		static get observedAttributes() {
			return ['active-class'];
		}

		get activeClass() {
			return this.hasAttribute('active-class')
				? this.getAttribute('active-class')
				: 'active';
		}

		set activeClass(newValue) {
			if (newValue) {
				this.setAttribute('active-class', newValue);
			} else {
				this.removeAttribute('active-class');
			}
		}

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();

				this.#observeLinks();
        this.addEventListener('click', this.#handleClick);
			}

			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#unobserveLinks();
      this.removeEventListener('click', this.#handleClick);
		}

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (!this.#initialized || oldValue === newValue) {
				return;
			}

			switch (attributeName) {
				case 'active-class': {
					this.#updateActiveClass(oldValue, newValue);

					break;
				}

				default: {
					console.warn(
						'Необработанное изменение атрибута',
						attributeName,
						oldValue,
						newValue
					);

					break;
				}
			}
		}

		#initialize() {
			this.#containerElement = this.querySelector('ul, ol, [role="list"]');
			this.#linkElements = this.querySelectorAll('a[href*="#"]');
			this.#hamburger_linkElements = document.querySelectorAll('.hamburger a[href*="#"]');

			this.#linkElements.forEach(link=>{
				link.addEventListener('click',this.#scrollToHandle)
			})
			this.#hamburger_linkElements.forEach(link=>{
				link.addEventListener('click',this.#scrollToHandleHamburger)
			})

			this.#createObserver();
		}

		#scrollToHandle  = this.#scrollTo.bind(this)
		#scrollToHandleHamburger  = this.#scrollToHamburger.bind(this)
		#scrollTo(e){
			window.scrollSmoothTo('#anchor_'+e.currentTarget.href.split('#anchor_')[1],600);
			e.preventDefault();
		}
		#scrollToHamburger(e){
			window.scrollSmoothTo('#anchor_'+e.currentTarget.href.split('#anchor_')[1],200);
			e.preventDefault();
		}

		#updateActiveClass(oldValue, newValue) {
			const activeLinkElements = this.querySelectorAll(`.${oldValue}`);

			if (activeLinkElements) {
				activeLinkElements.forEach(activeLinkElement => {
					activeLinkElement.classList.remove(oldValue);
					activeLinkElement.classList.add(newValue);
				});
			}
		}

		#createObserver() {
			this.#observer = new IntersectionObserver(this.#handleObserve, {
				rootMargin: '-300px 0px -200px 0px'
			});
		}

		#handleObserve = entries => {
			entries.forEach(entry => {
				const targetId = entry.target.getAttribute('id');

				const linkElement = this.querySelector(`a[href*="#anchor_${targetId}"]`);
				if (entry.isIntersecting) {

					linkElement.classList.add(this.activeClass);
					if (this.#hamburger_linkElements){
						this.#hamburger_linkElements.forEach((item)=>{

							if (item.hash.includes(`#anchor_${entry.target.id}`)){
								item.classList.add('active')
							}else{
								item.classList.remove('active')
							}
						})
					}

					const containerRect = this.#containerElement.getBoundingClientRect();
					const targetRect = linkElement.getBoundingClientRect();

					const isLinkElementVisible =
						targetRect.left >= containerRect.left &&
						targetRect.right <= containerRect.right;

					if (!isLinkElementVisible && this.#needScrolling) {
						this.#containerElement.scrollLeft =
							targetRect.left -
							containerRect.left +
							this.#containerElement.scrollLeft;
					}
				} else {
					linkElement.classList.remove(this.activeClass);
					if (this.#hamburger_linkElements){
						this.#hamburger_linkElements.forEach((item)=>{
							item.classList.remove('active');
						})
					}
				}
			});
		};

    #handleClick = () => {
      this.#needScrolling = false;

      if (this.#timerId !== null) {
        clearTimeout(this.#timerId);
        this.#timerId = null;
      }

      this.#timerId = setTimeout(() => {
        this.#needScrolling = true;
      }, 2500);
    }

		#observeLinks() {
			this.#linkElements.forEach(linkElement => {
				const target = document.querySelector(linkElement.hash.replace("anchor_",''));

				if (target) {
					this.#observer.observe(target);
				}
			});
		}

		#unobserveLinks() {
			this.#linkElements.forEach(linkElement => {
				const target = document.querySelector(linkElement.hash.replace("anchor_",''));
				if (target) {
					this.#observer.unobserve(target);
				}
			});
		}
	}
);
