import axios from 'axios';

customElements.define(
	'product-form',
	class extends HTMLElement {
		#initialized = false;
		#domain = '/';
		#formElement = null;
		#counterElement = null;
		#priceElement = null;
		#buttonElement = null;
		#changeQuantityTimer = null;

		static get observedAttributes() {
			return ['in-cart'];
		}

		get inCart() {
			return this.getAttribute('in-cart') === 'true';
		}

		set inCart(newValue) {
			if (newValue) {
				this.setAttribute('in-cart', newValue.toString());
			} else {
				this.removeAttribute('in-cart');
			}
		}
		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();

				this.#formElement.addEventListener('submit', this.#handleSubmit);
				this.#formElement.addEventListener('change', this.#handleChange);
			}
			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#formElement.removeEventListener('submit', this.#handleSubmit);
			this.#formElement.removeEventListener('change', this.#handleChange);
		}

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (!this.#initialized || oldValue === newValue) {
				return;
			}

			switch (attributeName) {
				case 'in-cart': {
					this.#updateFormAction();
					this.#updateVisibilityComponents();

					break;
				}

				default: {
					console.warn(
						'Необработанное изменение атрибута',
						attributeName,
						oldValue,
						newValue
					);

					break;
				}
			}
		}

		#initialize() {
			const baseElement = document.querySelector('base');

			if (baseElement && baseElement.href) {
				this.#domain = baseElement.href;
			}

			this.#formElement = this.querySelector('form');
			this.#counterElement = this.querySelector('counter-component');
			this.#priceElement = this.#counterElement.nextElementSibling;
			this.#buttonElement = this.querySelector('button[type="submit"]');

			this.#updateFormAction();
			this.#updateVisibilityComponents();
		}

		#updateFormAction() {
			this.#formElement.action = this.inCart
				? `${this.#domain}index.php?route=checkout/cart/edit`
				: `${this.#domain}index.php?route=checkout/cart/add`;
		}

		#updateVisibilityComponents() {
			this.#counterElement.hidden = !this.inCart;
			this.#priceElement.hidden = !this.inCart;
			this.#buttonElement.hidden = this.inCart;
		}

		#updatePrice(newPrice) {
			this.#priceElement.textContent = newPrice;

			const buttonPriceElement = this.#buttonElement.querySelector('b');

			if (buttonPriceElement) {
				buttonPriceElement.textContent = newPrice;
			} else {
				this.#buttonElement.textContent = newPrice;
			}
		}

		#handleSubmit = async event => {
			event.preventDefault();
			this.#buttonElement.disabled = true;

			try {
				const formData = new FormData(this.#formElement);

				const response = await axios.post(this.#formElement.action, formData);

				if (response.data.error) {
					return;
				}

				if (response.data.data?.quantity) {
					this.#counterElement.value = response.data.data.quantity;
				}

				this.inCart = Boolean(response.data.data?.quantity);

				this.#fireEvent();
			} catch (error) {
				console.error(error);
			} finally {
				this.#buttonElement.disabled = false;
			}
		};

						#handleQuantityChange = () => {
			if (this.#changeQuantityTimer !== null) {
				clearTimeout(this.#changeQuantityTimer);
				this.#changeQuantityTimer = null;
			}

			this.#changeQuantityTimer = setTimeout(() => {
				this.#formElement.requestSubmit();
			}, 300);
		};

		#handleOptionChange = async () => {
			try {
				const formData = new FormData(this.#formElement);

				const response = await axios.post(
					`${this.#domain}index.php?route=product/product/data1`,
					formData
				);

				if (response.data.error) {
					return;
				}

				this.inCart = Boolean(response.data.data.cart_count);
				this.#counterElement.value = response.data.data.cart_count;

				if (response.data.data.price) {
					this.#updatePrice(response.data.data.price);
				}
			} catch (error) {
				console.error(error);
			}
		};

		#handleChange = event => {
			if (event.target.name === 'quantity') {
				this.#handleQuantityChange();
			} else {
				this.#handleOptionChange();
			}
		};

		#fireEvent() {
			const event = new CustomEvent('cartChange', {
				bubbles: true,
				cancelable: true,
				composed: true
			});

			this.dispatchEvent(event);
		}
	}
);
