import axios from 'axios';
import IMask from 'imask';
import {suggestion} from "../../helpers";
customElements.define(
	'order-form',
	class extends HTMLElement {
		#initialized = false;
		#domain = '/';
		#formElement = null;
		#addressBlockElement = null;
		#pickupPointShippingBlockElement = null;
		#streetSuggestion = null;
		#suggestionList = [];
		#orderTotals = null
		#timoutSuggestion = null
		#preorderStatus = null
		#fields = {};
		// #successForm = null;
		#mask = null;

		// #shippingMethodsInputs = [];

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();
				for (const key in this.#fields) {
					this.#fields[key]?.input.addEventListener('focus',this.#handleFocusInput.bind(null, key));
				}
				this.#formElement.addEventListener('submit', this.#handleSubmit);
				this.#formElement.addEventListener('change', this.#handleChange);
				this.#fields.shipping_street.input.addEventListener('input',this.#handleStreetInput)
				// this.#fields.preorder_date.input.addEventListener('change',this.#handleChangePreorder)
				// this.#preorderStatus.addEventListener('change',this.#handleChangePreorderStatus)

			}

			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#formElement.removeEventListener('submit', this.#handleSubmit);
			this.#formElement.removeEventListener('change', this.#handleChange);
			this.#fields.shipping_street.input.removeEventListener('input',this.#handleStreetInput)
			// this.#fields.preorder_date.input.removeEventListener('change',this.#handleChangePreorder)
			// this.#preorderStatus.removeEventListener('change',this.#handleChangePreorderStatus)
			this.#mask.destroy();
		}

		#initialize() {

			this.#orderTotals = document.querySelector('order-totals')

			this.#streetSuggestion = this.querySelector('.shipping_street__suggest')


			const baseElement = document.querySelector('base');
			if (baseElement && baseElement.href) {
				this.#domain = baseElement.href;
			}

			this.#formElement = this.querySelector('form');
			this.#addressBlockElement = this.querySelector(
				'.order-form__block--address'
			);
			this.#pickupPointShippingBlockElement = this.querySelector(
				'[data-shipping-method-pickup-point]'
			);

			this.#fields.telephone = {
				input : this.querySelector('[name="telephone"]'),
				label : this.querySelector('.order-form__telephone'),
				err : this.querySelector('.err__telephone')
			}
			this.#fields.firstname = {
				input : this.querySelector('[name="firstname"]'),
				label : this.querySelector('.order-form__firstname'),
				err : this.querySelector('.err__firstname')
			}
			this.#fields.shipping_street = {
				input : this.querySelector('[name="shipping_street"]'),
				err : this.querySelector('.err__shipping_street')
			}
			this.#fields.shipping_house = {
				input : this.querySelector('[name="shipping_house"]'),
				err : this.querySelector('.err__shipping_house')
			}
			this.#fields.shipping_apartment = {
				input : this.querySelector('[name="shipping_apartment"]'),
				err : this.querySelector('.err__shipping_apartment')
			}
			this.#fields.shipping_entrance = {
				input : this.querySelector('[name="shipping_entrance"]'),
				err : this.querySelector('.err__shipping_entrance')
			}
			this.#fields.shipping_floor = {
				input : this.querySelector('[name="shipping_floor"]'),
				err : this.querySelector('.err__shipping_floor')
			}
			this.#fields.change_from = {
				input : this.querySelector('[name="change_from"]'),
				err : this.querySelector('.err__change_from')
			}
			this.#fields.preorder_date = {
				input : this.querySelector('[name="preorder_date"]'),
				err : this.querySelector('.err__preorder_date')
			}
			if (this.querySelector('[name="shipping_name"]')){
				this.#fields.shipping_name = {
					input : this.querySelector('[name="shipping_name"]'),
					err : this.querySelector('.err__shipping_name')
				}
			}else{
				this.#fields.shipping_name = null;
			}
			// this.#preorderStatus = this.querySelector('[name="preorder_status"]')
			// this.#successForm = document.querySelector('success-form')

			this.#mask = IMask(this.#fields.telephone.input, {
				mask: '+{7} (000) 000 0000'
			});

			this.#changeAddressBlockVisibility();
		}

		#outsideClick = (event)=>{
			console.log(event)
			if(!(event.target ===this.#streetSuggestion||this.#streetSuggestion.contains(event.target))){
				this.#streetSuggestion.hidden = true;
				this.#unSetClickOutsideSuggestion();
			}
		}
		#setClickOutsideSuggestion = ()=>{
			document.addEventListener('click',this.#outsideClick)
		}
		#unSetClickOutsideSuggestion = ()=>{
			document.removeEventListener('click',this.#outsideClick)
		}

		// #handleChangePreorderStatus = (event)=>{
		// 	console.log(event.target.value)
		// 	if (event.target.value==='0'){
		// 		this.#successForm.setAttribute('preorder','')
		// 	}
		//
		// }
		// #handleChangePreorder = (event)=>{
		// 	this.#successForm.setAttribute('preorder',event.target.value)
		// }

		#handleStreetInput = async event=>{

			clearTimeout(this.#timoutSuggestion)
			this.#timoutSuggestion =  setTimeout(async()=>{
				const params = {
					'count': 5,
					// "locations_geo": [{
					// 	"lat": coords[0],
					// 	"lon": coords[1],
					// 	"radius_meters": 3000
					// }],
					"from_bound": { "value": "city" },
					"to_bound": { "value": "street" },
					'query': event.target.value
				}
				const result  =await suggestion(params);
				this.#clearEventSuggestion()


				result?.map(res=>{
					const li = document.createElement('li');
					li.innerText =res.value
					li.classList.add('shipping_street__suggestItem')
					li.addEventListener('click',this.#handleClickSuggestionItem)
					this.#streetSuggestion.append(li)
					this.#suggestionList.push(li)
				});
				if(result?.length){
					this.#streetSuggestion.hidden=false
					this.#setClickOutsideSuggestion();
				}else{
					this.#streetSuggestion.hidden=true
					this.#unSetClickOutsideSuggestion();
				}
			},300);



		}
		#handleClickSuggestionItem = (event)=>{
			this.#streetSuggestion.hidden=true
			this.#fields.shipping_street.input.value = event.target.innerText
			this.#unSetClickOutsideSuggestion();

		}
		#clearEventSuggestion =()=>{
			this.#suggestionList.map((item)=>{
				item.remove()
			})
		}
		#handleFocusInput = key => {
			this.#fields[key].err.innerText = '';
			this.#fields[key].input.classList.remove('text-input--secondary');
			this.#fields[key].label?.classList.remove('form__label--accent');
		};
		#handleSubmit = async event => {
			event.preventDefault();

			try {
				const formData = new FormData(event.target);

				const response = await axios.post(
					`${this.#domain}index.php?route=checkout/checkout/order`,
					formData
				);
				// this.#successForm.openDialog()
				// return;
				if ( response.data.error && Object.keys(response.data.error).length) {
					for (const key in response.data.error) {
						if (this.#fields[key]){
							this.#fields[key].input.classList.add('text-input--secondary');
							this.#fields[key].label?.classList.add('form__label--accent');
							this.#fields[key].err.innerText = response.data.error[key];
						}
					}
					const firstErr = this.querySelector('.text-input--secondary')
					if (firstErr){
						document.querySelector('alert-dialog').showWarning('Исправьте ошибки на странице')
					}
				} else if(response.data.warning) {
					document.querySelector('alert-dialog').showWarning(response.data.warning)
				}else{
					if (response.data.redirect) {
						location.href = response.data.redirect;
					}
				}
			} catch (error) {
				console.error(error);
			}
		};

		#handleChange = event => {
			if (event.target.name === 'shipping') {
				this.#handleShippingChange();
			}
		};

		async #handleShippingChange() {
			try {
				this.#changeAddressBlockVisibility();
				const formData = new FormData(this.#formElement);
				const response = await axios.post(
					`${this.#domain}index.php?route=checkout/checkout/changeShippingMethod`,
					formData
				);
				if (response.data?.totals){

					this.#orderTotals.setAttribute('totals',response.data.totals)
				}

				//
				// console.log(response.data);
				//
				// if (!response.data.shipping_methods) {
				//   this.#fireEvent();
				// }
			} catch (error) {
				console.error(error);
			}
		}

		#changeAddressBlockVisibility() {
			if (!this.#addressBlockElement) return;
			this.#addressBlockElement.hidden =
				this.querySelector('input[name="shipping"]:checked').value ===
				'pickup_point';
			this.#pickupPointShippingBlockElement.hidden =
				this.querySelector('input[name="shipping"]:checked').value !==
				'pickup_point';
			const shipping = this.querySelector(
				'input[name="shipping"]:checked'
			).value;
			this.querySelector(
				`input[name="shipping_method"][value^="${shipping}"]`
			).checked = true;
		}

		// #fireEvent() {
		// 	const event = new CustomEvent('totalsChange', {
		// 		bubbles: true,
		// 		cancelable: true,
		// 		composed: true
		// 	});
		//
		// 	this.dispatchEvent(event);
		// }
	}
);
