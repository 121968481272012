import axios from 'axios';
import IMask from 'imask';
import { generateGradient } from '../../helpers';

const RegisterTemplate = document.createElement('template');
RegisterTemplate.innerHTML = `<form action="/index.php?route=account/register/register" class="registration__form">
    <div class="registration__form__column">
        <div class="form__group">
            <label for="order-form__name"  class="form__label form__label--required">Ваше имя</label>
            <input type="text" name="name" required placeholder="Фамилия Имя" id="order-form__name"
                   class="text-input  text-input--borderless form__input">
            <span class="err__name accent"></span>
        </div>
    </div>
    <div class="registration__form__column">
        <div class="form__group">
            <label for="order-form__email" class="form__label form__label--required">электронная
                почта</label>
            <input type="email" name="email" required placeholder="example@mail.com" id="order-form__email"
                   class="text-input  text-input--borderless form__input">
            <span class="err__email accent"></span>
        </div>
    </div>
    <div class="registration__form__column">
        <div class="form__group">
            <label for="order-form__birthday" class="form__label form__label--required">дата
                рождения</label>
            <input type="date" required name="birthday" id="order-form__birthday"
                   class="text-input  text-input--borderless form__input">
            <span class="err__birthday accent"></span>
        </div>
    </div>
    <div class="registration__form__column">
        <div class="form__group">
            <label for="order-form__phone" class="form__label form__label--required">ваш номер
                телефона</label>
            <input placeholder="+7" type="tel" name="phone" id="order-form__phone" required
                   class="text-input  text-input--borderless form__input">
            <span class="err__phone accent"></span>
        </div>
    </div>
    <div class="registration__form__column registration__form__column--middle">
        <div class="registration__description">
            <button type="submit" class="button button--secondary button--borderless"> зарегистрироваться</button>
            <p class="registration__description__text">* регистрируясь, вы даете согласие на обработку данных и
                соглашаетесь с условиями <a
                        class="accent"
                        href="/oferta/">оферты</a>
            </p>

            <span>у вас уже есть аккаунт? <button type="button" id="login" class="clear accent">войти</button></span>
        </div>
    </div>
</form>
<dialog class="dialog dialog--codeConfirm  dialog--login ">
    <button type="button" class="dialog__close-button hoverable"
            aria-label="{{ button_dialog_close }}">
        <svg width="24" height="24" class="dialog__close-icon" aria-hidden="true">
            <use xlink:href="catalog/view/theme/default/assets/dist/images/shared/sprite.svg#cross"></use>
        </svg>
    </button>
    <div class="dialog__content-container decorative-container">
        <div class="login">
            <div class="codeConfirm">

                <h3 class="title title--secondary login__title">ПОДТВЕРЖДЕНИЕ НОМЕРА</h3>
                <form class="form codeConfirm login__form" action="/index.php?route=account/login/login">
                    <div class="form__group">
                        <label for="" class="form__label">Введите код отправленный на номер
                        </label>
                        <input type="text" class="text-input form__input" name="code" inputmode="numeric"
                               autocomplete="one-time-code" placeholder="1234">
                        <input hidden name="phone">
                    </div>
                    <div class="form__group">
                        <button class="login__submit" type="submit">Подтвердить</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</dialog>
`;
customElements.define(
	'register-form',
	class extends HTMLElement {
		#initialized = false;
		#fields = {};
		#submit = null;
		#form = null;
		#mask = null;
		#dialog = {};
		#login = null;
		#backDropStyle = null;

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();
				this.#subscribeEvents();
			}
			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#unsubscribeEvents();
		}

		#initialize() {
			this.appendChild(RegisterTemplate.content.cloneNode(true));
			this.#fields = {
				name: {
					input: this.querySelector('[name=name]'),
					err: this.querySelector('.err__name'),
					label: this.querySelector('[for="order-form__name"]')
				},
				phone: {
					input: this.querySelector('[name=phone]'),
					err: this.querySelector('.err__phone'),
					label: this.querySelector('[for="order-form__phone"]')
				},
				birthday: {
					input: this.querySelector('[name=birthday]'),
					err: this.querySelector('.err__birthday'),
					label: this.querySelector('[for="order-form__birthday"]')
				},
				email: {
					input: this.querySelector('[name=email]'),
					err: this.querySelector('.err__email'),
					label: this.querySelector('[for="order-form__email"]')
				}
			};
			this.#submit = this.querySelector('[type=submit]');
			this.#submit.disabled=true
			this.#dialog = this.querySelector('dialog');
			this.#login = this.querySelector('#login');

			this.#dialog = {
				some: this.querySelector('dialog'),
				form: this.querySelector('dialog form'),
				phone: this.querySelector('dialog form  [name=phone]'),
				code: this.querySelector('dialog form  [name=code]'),
				submit: this.querySelector('dialog form  [type=submit]')
			};

			this.#form = this.querySelector('form');
			this.#mask = IMask(this.#fields.phone.input, {
				mask: '+{7} (000) 000 0000'
			});
			this.#backDropStyle = document.createElement('style');
			this.#backDropStyle.textContent = `.dialog--codeConfirm::backdrop {
                                                 background-image: ${generateGradient()};
                                               }
                                             `;
			this.#dialog.some.append(this.#backDropStyle);
		}

		#handleFocusInput = key => {
			this.#fields[key].err.innerText = '';
			this.#fields[key].input.classList.remove('text-input--secondary');
			this.#fields[key].label.classList.remove('form__label--accent');
		};
		#handleClockDialog = event => {
			const dialogDimensions = this.#dialog.some.getBoundingClientRect();
			if (
				event.clientX < dialogDimensions.left ||
				event.clientX > dialogDimensions.right ||
				event.clientY < dialogDimensions.top ||
				event.clientY > dialogDimensions.bottom ||
				event.target.closest('.dialog__close-button')
			) {
				this.#dialog.some.close();
			}
		};
		#handleChange = ()=>{
			this.#submit.disabled = (!this.#form.checkValidity());
		}
		#subscribeEvents = () => {
			this.#form.addEventListener('submit', this.#handleSubmit);
			this.#form.addEventListener('change',this.#handleChange)
			for (const key in this.#fields) {
				this.#fields[key].input.addEventListener(
					'focus',
					this.#handleFocusInput.bind(null, key)
				);
			}
			this.#dialog.some.addEventListener('click', this.#handleClockDialog);
			this.#dialog.form.addEventListener('submit', this.#handleDialogSubmit);
			console.log(this.#login)

			this.#login.addEventListener('click',this.#hanldeLoginClick);
		};
		#hanldeLoginClick = () => {
			const dialog = document.querySelector('login-form');
			dialog.openDialog();
		};

		#unsubscribeEvents = () => {
			this.#form.removeEventListener('submit', this.#handleSubmit);
			for (const key in this.#fields) {
				this.#fields[key].input.removeEventListener(
					'focus',
					this.#handleFocusInput
				);
			}
			this.#mask.destroy();
			this.#dialog.some.removeEventListener('click', this.#handleClockDialog);
		};
		#handleDialogSubmit = async event => {
			event.preventDefault();
			this.#dialog.submit.disabled = true;
			try {
				const formData = new FormData(this.#dialog.form);
				const response = await axios.post(this.#dialog.form.action, formData);
				if (response.data.errors && Object.keys(response.data.errors).length) {
					for (const key in response.data.errors) {
						if (key === 'warning') {
							document.querySelector('alert-dialog').showWarning(response.data.errors[key])
						}
					}
				} else {
					if (response.data.success) {
						location.reload();
					}
				}
			} catch (err) {
				console.log(err);
			}
			this.#dialog.submit.disabled = false;
		};
		#handleSubmit = async event => {
			event.preventDefault();
			// this.#dialog.some.showModal();
			this.#submit.disabled = true;
			try {
				const formData = new FormData(this.#form);
				const response = await axios.post(this.#form.action, formData);
				if (response.data.errors  && Object.keys(response.data.errors).length) {
					for (const key in response.data.errors) {
						if (key === 'warning') {
							document.querySelector('alert-dialog').showWarning(response.data.errors[key])
						}
						this.#fields[key].input.classList.add('text-input--secondary');
						this.#fields[key].label.classList.add('form__label--accent');
						this.#fields[key].err.innerText = response.data.errors[key];
					}
				} else {
					this.#dialog.some.showModal();
					this.#dialog.phone.value = this.#fields.phone.input.value;
				}
			} catch (err) {
				console.log(err);
			}
			this.#submit.disabled = false;
		};
	}
);
