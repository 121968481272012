import axios from 'axios';

class WishlistCount extends HTMLElement {
	static instances = [];

	#initialized = false;

	connectedCallback() {
		if (!this.#initialized) {
			if (!WishlistCount.instances.length) {
				document.addEventListener(
					'wishlistChange',
          WishlistCount.handleWishlistChange
				);
			}

      WishlistCount.instances.push(this);
		}

		this.#initialized = true;
	}

	disconnectedCallback() {
    const index = WishlistCount.instances.indexOf(this);

    if (index !== -1) {
      WishlistCount.instances.splice(index, 1);
    }

		if (!WishlistCount.instances.length) {
			document.removeEventListener(
				'wishlistChange',
				WishlistCount.handleWishlistChange
			);
		}
	}

	update(newValue) {
		this.textContent = newValue;
	}

	static handleWishlistChange = async event => {
		try {
			const domain = document.querySelector('base').href;
			const response = await axios.get(
				`${domain}index.php?route=account/wishlist/count`
			);

			if (response.data.data?.count !== undefined) {
				WishlistCount.instances.forEach(instance => instance.update(response.data.data?.count));
			}
		} catch (error) {
			console.error(error);
		}
	};
}

customElements.define('wishlist-count', WishlistCount);
