const template = document.createElement('template');
const domain = document.querySelector('base').getAttribute('href');

template.innerHTML = `
<div class="search-widget">
    <button type="button" class="button button--secondary hoverable search-widget__toggle-button">поиск</button>
    <form hidden action="${domain}index.php" method="GET" class="search-widget__form">
        <input type="hidden" name="route" value="product/search">
        <input type="search" name="search" placeholder="поиск" class="text-input text-input--filled text-input--secondary text-input--left-offset search-widget__input" aria-label="Название товара">
        <button type="submit" class="search-widget__submit-button hoverable" aria-label="Найти">
            <svg width="24" height="24" class="search-widget__submit-icon" aria-hidden="true">
                <use xlink:href="catalog/view/theme/default/assets/dist/images/shared/sprite.svg#magnifier"></use>
            </svg>
        </button>
         <button type="reset" class="search-widget__reset-button" aria-label="Очистить">
          <svg width="24" height="24" class="search-widget__submit-icon" aria-hidden="true">
                <use xlink:href="catalog/view/theme/default/assets/dist/images/shared/sprite.svg#cross"></use>
            </svg>
         </button>
    </form>
</div>
`;

customElements.define(
	'search-widget',
	class extends HTMLElement {
		#initialized = false;
		#formElement = null;
		#inputElement = null;
		#toggleButtonElement = null;

		static get observedAttributes() {
			return ['search'];
		}

		get search() {
			return this.getAttribute('search');
		}

		set search(newValue) {
			if (newValue) {
				this.setAttribute('search', newValue);
			} else {
				this.removeAttribute('search');
			}
		}

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();

        this.addEventListener('keyup', this.#handleKeyUp);
        this.#toggleButtonElement.addEventListener('click', this.#showForm);
			}

			this.#initialized = true;
		}

		disconnectedCallback() {
      this.removeEventListener('keyup', this.#handleKeyUp);
      this.#toggleButtonElement.removeEventListener('click', this.#showForm);
    }

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (!this.#initialized || oldValue === newValue) {
				return;
			}

			switch (attributeName) {
				case 'search': {
					this.#updateInputValue();

					break;
				}

				default: {
					console.warn(
						'Необработанное изменение атрибута',
						attributeName,
						oldValue,
						newValue
					);

					break;
				}
			}
		}

		#initialize() {
			this.appendChild(template.content.cloneNode(true));

			this.#formElement = this.querySelector('.search-widget__form');
			this.#inputElement = this.querySelector('.search-widget__input');
			this.#toggleButtonElement = this.querySelector('.search-widget__toggle-button');

			this.#updateInputValue();
		}

		#updateInputValue() {
			this.#inputElement.value = this.search;

      if (this.search && this.#formElement.hidden) {
        this.#showForm();
      }
		}

    #handleKeyUp = (event) => {
      if (event.key === 'Escape') {
        this.#hideForm();
      }
    }

    #showForm = () => {
      this.#formElement.hidden = false;
      this.#toggleButtonElement.hidden = true;

      this.#inputElement.focus();
    }

    #hideForm = () => {
      this.#formElement.hidden = true;
      this.#toggleButtonElement.hidden = false;

      this.#toggleButtonElement.focus();
    }
	}
);
