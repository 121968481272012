import axios from 'axios';

const containerTemplate = document.createElement('template');
containerTemplate.innerHTML = `
  <section class="cart-contents">
      <h2 class="visually-hidden"></h2>
      <ul class="cart-contents__list"></ul>
  </section>
`;

const itemTemplate = document.createElement('template');
itemTemplate.innerHTML = `
  <li class="cart-contents__item">
      <cart-product></cart-product>
  </li>
`;

customElements.define(
	'cart-contents',
	class extends HTMLElement {
		#initialized = false;
		#listElement = null;

		static get observedAttributes() {
			return ['heading', 'items'];
		}

		get heading() {
			return this.getAttribute('heading');
		}

		set heading(newValue) {
			if (newValue) {
				this.setAttribute('heading', newValue);
			} else {
				this.removeAttribute('heading');
			}
		}

		get items() {
			try {
				return this.getAttribute('items')
					? JSON.parse(this.getAttribute('items'))
					: [];
			} catch (error) {
				return [];
			}
		}

		set items(newValue) {
			if (newValue) {
				this.setAttribute('items', JSON.stringify(newValue));
			} else {
				this.removeAttribute('items');
			}
		}

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();
			}

			this.#initialized = true;
		}

		disconnectedCallback() {}

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (!this.#initialized || oldValue === newValue) {
				return;
			}

			switch (attributeName) {
        case 'heading': {
          this.#updateHeading();

          break;
        }

				case 'items': {
					this.#renderProducts();

					break;
				}

				default: {
					console.warn(
						'Необработанное изменение атрибута',
						attributeName,
						oldValue,
						newValue
					);

					break;
				}
			}
		}

		#initialize() {
			this.appendChild(containerTemplate.content.cloneNode(true));

			this.#listElement = this.querySelector('.cart-contents__list');
      this.#updateHeading();
			this.#renderProducts();
		}

    #updateHeading() {
      const headingElement = this.querySelector('h2');

      if (headingElement) {
        headingElement.textContent = this.heading;
      }
    }

		#renderProducts() {
			this.#listElement.innerHTML = '';

			if (!this.items.length) {
				return false;
			}

			const itemElements = this.items.map(item => {
        const itemElement = itemTemplate.content.cloneNode(true)
				const cartProduct = itemElement.querySelector('cart-product');
				cartProduct.product = item;

				return itemElement;
			});

			this.#listElement.append(...itemElements);
		}
	}
);

const cartContentsElements = document.querySelectorAll('cart-contents');

if (cartContentsElements.length) {
	try {
    const domain = document.querySelector('base').getAttribute('href');

		document.addEventListener('cartChange', async () => {
			const response = await axios.get(
				`${domain}index.php?route=checkout/cart/contents`
			);

			cartContentsElements.forEach(cartContentsElement => {
				cartContentsElement.items = response.data.data.products ?? '';
			});
		});
	} catch (error) {
		console.error(error);
	}
}
