import axios from 'axios';

const template = document.createElement('template');
template.innerHTML = `
<div class="coupon-input">
    <form action="" method="POST" class="coupon-input__form">
        <input type="text" name="coupon" placeholder="код" class="text-input coupon-input__input" aria-label="Введите промокод">
			<button type="submit" class="button button--borderless hoverable"></button>
    </form>
</div>
`;

customElements.define(
	'coupon-input',
	class extends HTMLElement {
		#initialized = false;
		#formElement = null;
		#inputElement = null;
		#buttonElement = null;

		static get observedAttributes() {
			return ['code'];
		}

		get code() {
			return this.getAttribute('code');
		}

		set code(newValue) {
			if (newValue) {
				this.setAttribute('code', newValue);
			} else {
				this.removeAttribute('code');
			}
		}

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();

				this.#formElement.addEventListener('submit', this.#handleSubmit);
				this.#inputElement.addEventListener('input', this.#updateButtonStatus);
			}

			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#formElement.removeEventListener('submit', this.#handleSubmit);
			this.#inputElement.removeEventListener('input', this.#updateButtonStatus);
		}

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (!this.#initialized || oldValue === newValue) {
				return;
			}

			switch (attributeName) {
				case 'code': {
					this.#updateFormAction();
					this.#updateInputValue();
					this.#updateButtonStatus();
					this.#updateButtonText();

					break;
				}

				default: {
					console.warn(
						'Необработанное изменение атрибута',
						attributeName,
						oldValue,
						newValue
					);

					break;
				}
			}
		}

		#initialize() {
			this.appendChild(template.content.cloneNode(true));

			this.#formElement = this.querySelector('form');
			this.#inputElement = this.querySelector('input');
			this.#buttonElement = this.querySelector('button');

			this.#updateFormAction();
			this.#updateInputValue();
			this.#updateButtonStatus();
			this.#updateButtonText();
		}

		#updateFormAction() {
			const domain = document.querySelector('base').getAttribute('href');
			this.#formElement.action = this.code
				? `${domain}index.php?route=extension/total/coupon/cancel`
				: `${domain}index.php?route=extension/total/coupon/apply`;
		}

		#updateInputValue() {
			this.#inputElement.value = this.code;
		}

		#updateButtonStatus = () => {
			this.#buttonElement.disabled = !this.#inputElement.value && !this.code;
		};

		#updateButtonText = () => {
			this.#buttonElement.textContent = this.code ? 'отменить' : 'применить';
		};

		#handleSubmit = async event => {
			event.preventDefault();
			this.#buttonElement.disabled = true;

			try {
				const formData = new FormData(this.#formElement);

				const response = await axios.post(this.#formElement.action, formData);

				if (!this.code) {
					if (response.data.error) {
						document.querySelector('alert-dialog').showWarning(response.data.error)
					} else {
						this.code = response.data.data.coupon;
						document.querySelector('alert-dialog').showWarning(response.data.message)
						this.#fireEvent();
					}
				} else if (response.data.message) {
					this.code = '';
					document.querySelector('alert-dialog').showWarning(response.data.message)
					this.#fireEvent();
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.#buttonElement.disabled = false;
			}
		};

		#fireEvent() {
			const event = new CustomEvent('totalsChange', {
				bubbles: true,
				cancelable: true,
				composed: true
			});

			this.dispatchEvent(event);
		}
	}
);
