export default class Error {
    static add(parent, errors) {
        const error_block = parent.querySelector('.error-block')
        error_block.innerHTML = ''

        if (typeof errors == 'object') {
            errors.map(error => {
                const span = document.createElement('span')
                span.innerHTML = '! ' +  error

                error_block.append(span)
            })
        } else if (typeof errors == 'string') {
            const span = document.createElement('span')
            span.innerHTML = '! ' + errors

            error_block.append(span)
        }

        error_block.hidden = false
    }

    static clear() {
        document.querySelectorAll('.error-block').forEach(error => {
            error.innerHTML = ''
        })
    }
}