import IMask from 'imask';
import axios from "axios";
customElements.define(
	'feedback-form',
	class extends HTMLElement {
        #initialized = false;
        #fields={};
        #mask;
		#form;
		#formSubmit;
		#status=false
		connectedCallback(){
			// this.attachShadow({mode: 'open'});
			if (!this.#initialized) {
				this.#initialize();
			}
			this.#initialized = true;
		};
		#initialize = () => {
			this.#fields = {

				firstname: {
					input: this.querySelector('[name=firstname]'),
					err: this.querySelector('.err__firstname'),
					label: this.querySelector('[for="team-form__firstname"]')
				},

				phone: {
					input: this.querySelector('[name=phone]'),
					err: this.querySelector('.err__phone'),
					label: this.querySelector('[for="team-form__phone"]')
				},

				email: {
					input: this.querySelector('[name=email]'),
					err: this.querySelector('.err__email'),
					label: this.querySelector('[for="team-form__email"]')
				},
				comment: {
					input: this.querySelector('[name=comment]'),
					err: this.querySelector('.err__comment'),
					label: this.querySelector('[for="team-form__comment"]')
				}
			};
            this.#mask = IMask(this.#fields.phone.input, {
                mask: '+{7} (000) 000 0000'
            });
			this.#form = this.querySelector('form');
			this.#formSubmit = this.querySelector('[type="submit"]');


			this.#subscribeEvents()
		};
		#subscribeEvents = () => {
			// this.#form.addEventListener('change',this.#handleFormSubmit)
			this.#form.addEventListener('submit',this.#handleFormSubmit)
			this.#form.addEventListener('change',this.#handleChange)
		};
		#handleChange = ()=>{
			console.log(this.#fields.phone.input.value.length,this.#fields.email.input.value.length)

			if (this.#form.checkValidity()&&(this.#fields.phone.input.value.length||this.#fields.email.input.value.length)){
				this.#formSubmit.disabled=false
			}else{
				this.#formSubmit.disabled=true
			}
		}
		#handleFormSubmit= async (e)=>{

			e.preventDefault();
			const formData = new FormData(this.#form)
			const response = await axios.post('/index.php?route=information/feedback/sendForm',formData)
			if (response.data.errors && Object.keys(response.data.errors).length) {
				for (const key in response.data.errors) {
					if (key === 'warning') {
						document.querySelector('alert-dialog').showWarning(response.data.errors[key])
					}else{
						this.#fields[key].input.classList.add('text-input--secondary');
						this.#fields[key].label.classList.add('form__label--accent');
						this.#fields[key].err.innerText = response.data.errors[key];
					}
				}
			} else {
				document.querySelector('alert-dialog').showWarning('Ваш отзыв отправлен')
				// location.reload();
			}

		}

		disconnectedCallback(){};
	}
);
