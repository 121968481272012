const template = document.createElement('template');
template.innerHTML = `
  <button type="button" class="scroll-button hoverable">
    <svg width="48" height="48" class="scroll-button__icon">
        <use xlink:href=""></use>
    </svg>
  </button>
`;

const directions = ['top', 'bottom'];

customElements.define(
	'scroll-button',
	class extends HTMLElement {
		#initialized = false;





		#button = null;
		#icons = {
			top: 'catalog/view/theme/default/assets/dist/images/shared/sprite.svg#chevron-up',
			bottom:
				'catalog/view/theme/default/assets/dist/images/shared/sprite.svg#chevron-down'
		};

		static get observedAttributes() {
			return ['direction'];
		}

		get direction() {
			return directions.includes(this.getAttribute('direction'))
				? this.getAttribute('direction')
				: directions[0];
		}

		set direction(newValue) {
			directions.includes(newValue)
				? this.setAttribute('direction', newValue)
				: directions[0];
		}

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();
				this.#button.addEventListener('click', this.#handleClick);
				window.addEventListener('scroll', this.#updateButtonVisible);
			}

			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#button.removeEventListener('click', this.#handleClick);
			window.removeEventListener('scroll', this.#updateButtonVisible);
		}

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (!this.#initialized || oldValue === newValue) {
				return;
			}

			switch (attributeName) {
				case 'direction': {
					this.#updateButtonVisible();
					this.#updateButtonLabel();
					this.#updateIcon();
					break;
				}

				default: {
					console.warn(
						'Необработанное изменение атрибута',
						attributeName,
						oldValue,
						newValue
					);

					break;
				}
			}
		}

		#initialize() {
			this.appendChild(template.content.cloneNode(true));
			this.#button = this.querySelector('button');
			this.#updateButtonVisible();
			this.#updateButtonLabel();
			this.#updateIcon();
		}

		#updateButtonVisible = () => {
			if (this.direction === 'top') {
				this.hidden = window.scrollY <= window.innerHeight;
			} else {
				this.hidden =
					window.scrollY >= document.body.scrollHeight - window.innerHeight * 2;
			}
		};

		#updateButtonLabel() {
			this.#button.setAttribute(
				'aria-label',
				this.direction === 'top' ? 'В начало страницы' : 'В конец страницы'
			);
		}

		#updateIcon() {
			this.#button
				.querySelector('use')
				.setAttribute('xlink:href', this.#icons[this.direction]);
		}

		#handleClick = () => {
			if (this.direction === 'top') {
				window.scrollTo({
					top: '0',
					left: '0',
					behavior: 'smooth'
				});
			} else {
				window.scrollTo({
					top: document.body.scrollHeight - window.innerHeight,
					left: '0',
					behavior: 'smooth'
				});
			}
		};
	}
);
