import axios from 'axios';

class CartTotal extends HTMLElement {
	static instances = [];

	#initialized = false;

	connectedCallback() {
		if (!this.#initialized) {
			if (!CartTotal.instances.length) {
				document.addEventListener(
					'cartChange',
          CartTotal.handleCartChange
				);
			}

      CartTotal.instances.push(this);
		}

		this.#initialized = true;
	}

	disconnectedCallback() {
    const index = CartTotal.instances.indexOf(this);

    if (index !== -1) {
      CartTotal.instances.splice(index, 1);
    }

		if (!CartTotal.instances.length) {
			document.removeEventListener(
				'wishlistChange',
				CartTotal.handleCartChange
			);
		}
	}

	update(newValue) {
		this.textContent = newValue;
	}

	static handleCartChange = async event => {
		try {
			const domain = document.querySelector('base').href;
			const response = await axios.get(
				`${domain}index.php?route=checkout/cart/total`
			);

			if (response.data.data?.total !== undefined) {
				CartTotal.instances.forEach(instance => instance.update(response.data.data?.total));
				document.querySelector('.page-header__text').innerHTML = response.data.data?.comment
			}
		} catch (error) {
			console.error(error);
		}
	};
}

customElements.define('cart-total', CartTotal);
