customElements.define(
	'account-address',
	class extends HTMLElement {
		#initialized = false;
		#newAddress
		#addAdresses;
		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();
			}
			this.#initialized = true;
		}

		#initialize = () => {
			this.#addAdresses = this.querySelector('.addAddress')
			this.#newAddress =  this.querySelector('address-row[addressId="0"]')
			this.#subscribeEvents()
		};
		#subscribeEvents = () => {
			this.#addAdresses.addEventListener('click',this.#handelClickAddAddresses)
		};

		disconnectedCallback() {
		}
		#handelClickAddAddresses = event => {
			this.#newAddress.openForm()
		};
	}
);
