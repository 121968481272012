customElements.define(
	'mouse-scrollable-list',
	class extends HTMLElement {
		#initialized = false;
		#listElement = null;
		#isDragging = false;
		#startX = 0;
		#scrollLeft = 0;
		#container = null;

		static get observedAttributes() {
			return ['scroll-disabled'];
		}

		get scrollDisabled() {
			return this.getAttribute('scroll-disabled') === 'true';

		}

		set scrollDisabled(newValue) {
			if (newValue) {
				if (newValue === 'false') {
					this.setAttribute('scroll-disabled', 'false');
					this.#container.classList.add('fixed')
				} else {
					this.setAttribute('scroll-disabled', 'true');
					this.#container.classList.remove('fixed')
				}
			} else {
				this.removeAttribute('scroll-disabled');
				this.#container.classList.add('fixed')
			}
		}

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();

				if (this.scrollDisabled) {
					this.#unsubscribe();

				} else {


					this.#subscribe();

				}
			}

			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#unsubscribe();
		}

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (!this.#initialized || oldValue === newValue) {
				return;
			}

			switch (attributeName) {
				case 'scroll-disabled': {
					if (this.scrollDisabled) {
						this.#unsubscribe();
					} else {
						this.#subscribe();
					}

					break;
				}

				default: {
					console.warn(
						'Необработанное изменение атрибута',
						attributeName,
						oldValue,
						newValue
					);

					break;
				}
			}
		}

		#initialize() {
			this.#listElement = this.querySelector('ul, ol, [role="list"]');
			this.#container = document.querySelector('.main-menu__content-container');

		}

		#subscribe() {


			this.#listElement.addEventListener('mousedown', this.#handleMouseDown);
			this.#listElement.addEventListener('mouseup', this.#handleMouseUp);
			this.#listElement.addEventListener('mousemove', this.#handleMouseMove);
			this.#listElement.addEventListener('mouseleave', this.#handleMouseLeave);
			this.#listElement.addEventListener('wheel', this.#handleWheel);
			this.#listElement
				.querySelectorAll('a')
				.forEach(linkElement =>
					linkElement.addEventListener('dragstart', event =>
						event.preventDefault()
					)
				);
		}

		#unsubscribe() {




			this.#listElement.removeEventListener('mousedown', this.#handleMouseDown);
			this.#listElement.removeEventListener('mouseup', this.#handleMouseUp);
			this.#listElement.removeEventListener('mousemove', this.#handleMouseMove);
			this.#listElement.removeEventListener(
				'mouseleave',
				this.#handleMouseLeave
			);
			this.#listElement.removeEventListener('wheel', this.#handleWheel);
		}

		#handleMouseDown = event => {
			if (event.touches) return;

			this.#isDragging = true;
			this.#startX = event.pageX - this.#listElement.offsetLeft;
			this.#scrollLeft = this.#listElement.scrollLeft;
			this.#listElement.style.cursor = 'grabbing';
		};

		#handleMouseUp = () => {
			this.#isDragging = false;
			this.#listElement.style.cursor = 'grab';
		};

		#handleMouseMove = event => {
			if (!this.#isDragging) return;

			event.preventDefault();
			const x = event.pageX - this.#listElement.offsetLeft;
			const walk = x - this.#startX;
			this.#listElement.scrollLeft = this.#scrollLeft - walk;
		};

		#handleMouseLeave = () => {
			this.#isDragging = false;
			this.#listElement.style.cursor = 'grab';
		};

		#handleWheel = event => {
			event.preventDefault();

			if (Math.abs(event.deltaX) <= Math.abs(event.deltaY)) {
				this.#listElement.scrollLeft += event.deltaY;
			} else {
				this.#listElement.scrollLeft += event.deltaX;
			}
		};
	}
);
