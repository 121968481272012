import axios from 'axios';
import IMask from 'imask';
import { generateGradient } from '../../helpers';
import Error from '../../../../../../../../../frontend/dev/src/js/classes/Error';

function createDialog(data = {}) {
	const products = JSON.parse(data.products);
	const template = document.createElement('template');
	const preorder =data.preorder ? 'доставка в '+data.preorder :  'доставка сегодня,<br> в ближайшее время';
	let arrList = '';
	products?.map(product => {
		arrList += `<li class="successDialog__productSuccess productSuccess">
                    <span class="productSuccess__quantity">${product.quantity}x</span>
                    <span class="productSuccess__title text">${product.name}</span>
                  <span class="productSuccess__weight"> ${product.weight} г.</span>
                    <div class="productSuccess__summ text"> ${product.total} Р</div>
                </li>`;
	});
	template.innerHTML = `
<dialog id="successDialog" class="dialog dialog--success">
    <button type="button" class="dialog__close-button hoverable">
        <svg width="24" height="24" class="dialog__close-icon" aria-hidden="true">
            <use xlink:href="catalog/view/theme/default/assets/dist/images/shared/sprite.svg#cross"></use>
        </svg>
    </button>
    <div class="dialog__content-container decorative-container">
        <div class="successDialog">
            <div class="successDialog__title text"> ${preorder}</div>
            <ul class="successDialog__products">${arrList}</ul>
            <div class="separator separator--gray  order-form__separator"> </div>
            <div class="successDialog__orderDetail">
            <order-totals variant="secondary" totals='${
							data.totals
						}' ></order-totals>
            <a href="/" class="button button--borderless  hoverable">оки доки ;)</a>
			</div>
        </div>
</dialog>
`;
	return template;
}

customElements.define(
	'success-form',
	class extends HTMLElement {
		#initialized = false;
		#dialog = null;
		#backDropStyle = document.createElement('style');

		static get observedAttributes() {
			return ['totals','products','preorder'];
		}

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();
				this.#subscribeEvents();
				this.openDialog()
			}
			this.#initialized = true;
		}

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (!this.#initialized || oldValue === newValue) {
				return;
			}

			switch (attributeName) {
				case 'totals':
				case 'products':
				case 'preorder': {
					this.#unsubscribeEvents()
					this.#render({
						preorder: this.getAttribute('preorder'),
						totals: this.getAttribute('totals'),
						products: this.getAttribute('products')
					});
					this.#subscribeEvents()

					break;
				}
				default: {
					console.warn(
						'Необработанное изменение атрибута',
						attributeName,
						oldValue,
						newValue
					);
					break;
				}
			}
		}

		disconnectedCallback() {
			this.#unsubscribeEvents();
		}

		openDialog() {
			this.#backDropStyle.textContent = `dialog::backdrop {
                                                 background-image: ${generateGradient()};
                                               }`;
			this.#dialog.dialog.append(this.#backDropStyle);
			this.#dialog.dialog.showModal();
		}

		#render(data = {}) {
			this.innerHTML = '';
			this.appendChild(createDialog(data).content.cloneNode(true));
			this.#dialog = {
				dialog: this.querySelector('#successDialog')
			};
		}

		#initialize() {
			// this.#mask = IMask(this.#dialogLogin.phone.input, {
			//     mask: '+{7} (000) 000 0000'
			// });

			this.#render({
				preorder: this.getAttribute('preorder'),
				totals: this.getAttribute('totals'),
				products: this.getAttribute('products')
			});

		}

		#subscribeEvents = () => {
			this.#dialog.dialog.addEventListener('click', this.#handleCloseDialog);
		};
		#unsubscribeEvents = () => {
			this.#dialog.dialog.removeEventListener('click', this.#handleCloseDialog);
		};
		#handleCloseDialog = event => {
			const dialogDimensions = this.#dialog.dialog.getBoundingClientRect();
			if (
				event.clientX < dialogDimensions.left ||
				event.clientX > dialogDimensions.right ||
				event.clientY < dialogDimensions.top ||
				event.clientY > dialogDimensions.bottom ||
				event.target.closest('.dialog__close-button')
			) {

				location = "/";
				this.#backDropStyle.remove();
				this.#dialog.dialog.close();
			}
		};
	}
);
