import axios from 'axios';
import IMask from 'imask';
import {generateGradient} from '../../helpers';

const LoginTemplate = document.createElement('template');
LoginTemplate.innerHTML = `
    <dialog  id="loginForm" class="dialog dialog--login">
        <button type="button" class="dialog__close-button hoverable">
            <svg width="24" height="24" class="dialog__close-icon" aria-hidden="true">
                <use xlink:href="catalog/view/theme/default/assets/dist/images/shared/sprite.svg#cross"></use>
            </svg>
        </button>
        <div class="dialog__content-container decorative-container">
            <div class="login">
                <h3 class="title title--secondary login__title">вход</h3>
                <form  class="login__form" action="">
                    <div class="form__group form__group--phone">
                        <label for="" class="form__label">Ваш номер
                            телефона </label>
                        <input type="tel" name="phone" placeholder="+7" class="text-input form__input"
                               aria-label="Введите ваш номер телефона">
                    </div> 
                     <div  hidden class="form__group form__group--code">
                        <label for="" class="form__label">Введите код отправленный на номер <span class="login__phoneNumber"></span></label>
                       <input type="text" class="text-input form__input" name="code" inputmode="numeric" autocomplete="one-time-code" placeholder="1234">
                    </div>
                    <div class="form__group">
                        <button disabled  class="login__submit hoverable" type="submit">Войти</button> 
                    </div>
                    <p class="login__subtitle">
                        нет аккаунта?
                        <a href="/create-account/ ">зарегистрироваться</a>
                    </p>
                </form>
            </div>

        </div>
    </dialog>
`;

customElements.define(
    'login-form',
    class extends HTMLElement {
        #stage=1;
        #initialized = false;
        #dialog = null;
        #mask = null;
        #backDropStyle = document.createElement('style');

        connectedCallback() {
            if (!this.#initialized) {
                this.#initialize();
                this.#subscribeEvents();
            }
            this.#initialized = true;
        }

        disconnectedCallback() {
            this.#mask.destroy();

            this.#unsubscribeEvents();
        }

        openDialog() {
            this.#backDropStyle.textContent = `dialog::backdrop {
                                                 background-image: ${generateGradient()};
                                               }`;

            this.#dialog.dialog.append(this.#backDropStyle);
            this.#dialog.dialog.showModal();
        }

        #initialize() {
            // this.#mask = IMask(this.#dialogLogin.phone.input, {
            //     mask: '+{7} (000) 000 0000'
            // });
            this.appendChild(LoginTemplate.content.cloneNode(true));
            this.#dialog = {
                dialog: this.querySelector('#loginForm'),
                phone: this.querySelector('#loginForm [name=phone]'),
                code: this.querySelector('#loginForm [name=code]'),
                groupPhone: this.querySelector('#loginForm .form__group--phone'),
                groupCode: this.querySelector('#loginForm .form__group--code'),
                phoneNumber: this.querySelector('#loginForm .login__phoneNumber'),
                form: this.querySelector('#loginForm form'),
                submit: this.querySelector('#loginForm [type=submit]'),
            };
        }
        #handleKEY=(event)=>{
            if(event.keyCode===13){
                this.#handleSubmit(event);
            }
        }
        #handleSubmit=async event =>{
            event.preventDefault();
            this.#dialog.submit.disabled=true;

            try {
                const formData = new FormData(this.#dialog.form);
                let response = null;
                if (this.#stage===1){
                     response = await axios.post('/index.php?route=account/login/code', formData);
                    if (response.data.success) {
                        this.#dialog.groupPhone.hidden=true
                        this.#dialog.groupCode.hidden=false
                        this.#dialog.phoneNumber.innerText = this.#dialog.phone.value
                        this.#stage=2;
                    } else if (response.data.errors && Object.keys(response.data.errors).length) {
                        for (const key in response.data.errors) {
                            document.querySelector('alert-dialog').showWarning(response.data.errors[key])

                        }
                        // Error.add(this.phone_block, response.error)
                    }
                }else{
                    response = await axios.post('/index.php?route=account/login/login', formData);
                    if (response.data.success) {
                        location.reload()
                    } else if (response.data.errors && Object.keys(response.data.errors).length) {
                        for (const key in response.data.errors) {
                            document.querySelector('alert-dialog').showWarning(response.data.errors[key])

                        }
                    }
                    this.#dialog.submit.disabled=false;
                }

            } catch (err) {
                console.log(err);
            }

        }

        #subscribeEvents = () => {
            this.#mask = IMask(this.#dialog.phone, {
                mask: '+{7} (000) 000 0000'
            });
            this.#dialog.phone.addEventListener('input',this.#handelPhoneInput)
            this.#dialog.code.addEventListener('input',this.#handelCodeInput)
            this.#dialog.dialog.addEventListener('click', this.#handleCloseDialog);
            this.#dialog.form.addEventListener('submit',this.#handleSubmit);
            this.#dialog.form.addEventListener('keydown',this.#handleKEY);


            this.addEventListener('keydown',function (event){
                if(event.keyCode===13){
                    event.preventDefault()
                }
            })
        }
        #unsubscribeEvents = () => {
            this.#mask.destroy();
            this.#dialog.dialog.removeEventListener('click', this.#handleCloseDialog);
            this.#dialog.phone.removeEventListener('input',this.#handelPhoneInput)

            this.#dialog.form.removeEventListener('submit',this.#handleSubmit);
            this.#dialog.form.removeEventListener('keydown',this.#handleKEY);
        }
        #handelPhoneInput=(event)=>{
            if (event.target.value.length>=17){
                this.#dialog.submit.disabled = false;
            }else{
                this.#dialog.submit.disabled = true;
            }

        }
        #handelCodeInput=(event)=>{
            if (event.target.value.length>=4){
                this.#dialog.submit.disabled = false;
            }else{
                this.#dialog.submit.disabled = true;
            }

        }
        #handleCloseDialog =  (event) => {
            const dialogDimensions = this.#dialog.dialog.getBoundingClientRect();
            if (
                event.clientX < dialogDimensions.left ||
                event.clientX > dialogDimensions.right ||
                event.clientY < dialogDimensions.top ||
                event.clientY > dialogDimensions.bottom ||
                event.target.closest('.dialog__close-button')
            ) {
                this.#backDropStyle.remove();
                this.#dialog.dialog.close();
            }
        };
    }
);
