import axios from 'axios';

const template = document.createElement('template');
template.innerHTML = `
<form action="" method="POST" class="wishlist-button">
    <input type="hidden" name="product_id" value="" class="wishlist-button__input">
    <button type="submit" class="wishlist-button__button hoverable">
        <svg width="34" height="34" class="wishlist-button__icon">
            <use xlink:href="catalog/view/theme/default/assets/dist/images/shared/sprite.svg#heart"></use>
        </svg>
    </button>
</form>
`;

customElements.define(
	'wishlist-button',
	class extends HTMLElement {
		#initialized = false;
		#formElement = null;
		#inputElement = null;
		#buttonElement = null;

		static get observedAttributes() {
			return ['product-id', 'in-wishlist'];
		}

		get productId() {
			return this.getAttribute('product-id');
		}

		set productId(newValue) {
			if (newValue) {
				this.setAttribute('product-id', newValue);
			} else {
				this.removeAttribute('product-id');
			}
		}

		get inWishlist() {
			return this.getAttribute('in-wishlist') === 'true';
		}

		set inWishlist(newValue) {
			if (newValue) {
				this.setAttribute('in-wishlist', newValue.toString());
			} else {
				this.removeAttribute('in-wishlist');
			}
		}

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();

				this.#formElement.addEventListener('submit', this.#handleSubmit);
			}

			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#formElement.removeEventListener('submit', this.#handleSubmit);
		}

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (!this.#initialized || oldValue === newValue) {
				return;
			}

			switch (attributeName) {
				case 'product-id': {
					this.#updateInputValue();

					break;
				}

				case 'in-wishlist': {
					this.#updateFormAction();
          this.#updateButton();

					break;
				}

				default: {
					console.warn(
						'Необработанное изменение атрибута',
						attributeName,
						oldValue,
						newValue
					);

					break;
				}
			}
		}

		#initialize() {
			this.appendChild(template.content.cloneNode(true));

			this.#formElement = this.querySelector('.wishlist-button');
			this.#inputElement = this.querySelector('.wishlist-button__input');
			this.#buttonElement = this.querySelector('.wishlist-button__button');

			this.#updateFormAction();
			this.#updateInputValue();
      this.#updateButton();
		}

		#updateFormAction() {
			const domain = document.querySelector('base').getAttribute('href');
			this.#formElement.action = this.inWishlist
				? `${domain}index.php?route=account/wishlist/remove`
				: `${domain}index.php?route=account/wishlist/add`;
		}

		#updateInputValue() {
			this.#inputElement.value = this.productId;
		}

		#updateButton() {
			this.#buttonElement.setAttribute(
				'aria-label',
				this.inWishlist ? 'Убрать из желаемого' : 'Добавить в желаемое'
			);

      this.inWishlist
        ? this.#buttonElement.classList.add('wishlist-button__button--in-wishlist')
        : this.#buttonElement.classList.remove('wishlist-button__button--in-wishlist');
		}

		#handleSubmit = async event => {
			event.preventDefault();
			this.#buttonElement.disabled = true;

			try {
				const formData = new FormData(this.#formElement);

				const response = await axios.post(this.#formElement.action, formData);

				if (response.data.message) {
					this.inWishlist = !this.inWishlist;

					this.#fireEvent(this.inWishlist ? 'add' : 'remove');
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.#buttonElement.disabled = false;
			}
		};

		#fireEvent(action = '') {
			const event = new CustomEvent('wishlistChange', {
				detail: {
					action: action,
					productId: this.productId
				},
				bubbles: true,
				cancelable: true,
				composed: true
			});

			this.dispatchEvent(event);
		}
	}
);
