import axios from "axios";
export const generateGradient = () => {
	const colors = ['#6AEBC1', '#FCFA2F', '#F684D6'];
  const colorsCount = Math.round(Math.random() + 2);
  const shuffledColors = colors.sort(() => Math.random() - 0.5);
  const gradientColors = shuffledColors.slice(0, colorsCount);

  const randomSize1 = Math.floor(Math.random() * 50) + 51;
  const randomSize2 = Math.floor(Math.random() * 50) + 51;
  const randomPositionX = Math.floor(Math.random() * 100) + 1;
  const randomPositionY = Math.floor(Math.random() * 100) + 1;

  let gradient = `radial-gradient(${randomSize1}% ${randomSize2}% at ${randomPositionX}% ${randomPositionY}%,`;

  gradientColors.forEach((gradientColor, index) => {
    if (index === 0) {
      gradient += ` ${gradientColor} 0%,`;
    } else if (index + 1 === gradientColors.length) {
      gradient += ` ${gradientColor} 100%`;
    } else {
      gradient += ` ${gradientColor} ${Math.floor(Math.random() * 100) + 1}%,`;
    }
  });

  gradient += ')';

  return gradient;
};

export const suggestion = async (params) => {
  const token = '89ec13d42c338b20a50ca5a156686c4f35abc2a7'
  const axiosDadata = axios.create()
  axiosDadata.defaults.baseURL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/';
  axiosDadata.defaults.headers = {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Authorization": `Token ${token}`
  }
  const data={

    "locations_boost": [
      {"kladr_id": "6800000400000"},
    ],
    ...params,
  }

  return axiosDadata.post('suggest/address', data).then((res) => {
    if (res.data.suggestions.length) {
      return res.data.suggestions
    } else {
      return null
    }
  }).catch((err) => {
    console.log(err)
    alert('Произошла ошибка')
    return null
  })

}