customElements.define(
	'cuisine-tab',
	class extends HTMLElement {
		#initialized = false;
			#checkbox = null;
		#value = null;
		#cuisineID = 0;
		get cuisineID() {
			return this.getAttribute('cuisine-id');
		}
		#handleChange = event => {
			this.#value = this.#checkbox.checked
			window.cuisine[this.cuisineID] = this.#value
		};

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();
			}
			this.#checkbox.addEventListener('change', this.#handleChange);
			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#checkbox.removeEventListener('change', this.#handleChange);
		}

		#initialize() {
			if (!window.cuisine){
				window.cuisine = new Proxy(
					{
					},
					{
						get(target, p) {
							if (p in target){
								return target[p]
							}
						},
						set(target, p, newValue) {
							target[p] = newValue;
							const arrCuisine = [];

							for (const key in target){
								if (target[key]){
									arrCuisine.push(key);
								}
							}
							const productsItemList  = document.querySelectorAll('.product-item')
							if (productsItemList.length){
								productsItemList.forEach((node)=>{
									node.parentNode.hidden= false;
									if (arrCuisine.length){
										if (!arrCuisine.includes(  node.dataset.cuisineId)|| !node.dataset.cuisineId){
											node.parentNode.hidden= true;
										}
									}
								})
							}
							const sectionProductList = document.querySelectorAll('.product-list')
								if (sectionProductList.length){
									sectionProductList.forEach((node)=>{
										node.hidden =false;
										const findWithOneElement = node.querySelector('.product-list__item:not([hidden])');
										if (!findWithOneElement){
											node.hidden=true
										}
									})
								}
							return true
						}
					}
				)
			}
			this.#checkbox = this.querySelector('input');
            this.#cuisineID = this.cuisineID
			this.#value = this.#checkbox.checked;
				window.cuisine[this.cuisineID]=this.#value
		}
	}
);
