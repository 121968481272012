import axios from 'axios';
import './libs/scroll-timeline';
import './libs/request-submit-polyfill';
import '@zachleat/details-utils';
import {register as swiperRegister} from 'swiper/element/bundle';

import './components/shared/CounterComponent';
import './components/shared/ScrollButton';
import './components/shared/AllertDialog';
import './components/shared/SearchWidget';
import './components/shared/MouseScrollableList';
import './components/shared/ActiveLinkHighlighter';
import './components/shared/ButtonNext';
import './components/catalog/ProductForm';
import './components/catalog/CuisineTab';
import './components/catalog/WishlistCount';
import './components/catalog/WishlistButton';
import './components/checkout/OrderTotals';
import './components/checkout/DiscountSelector';
import './components/checkout/CouponInput';
import './components/checkout/RewardForm';
import './components/checkout/CartCount';
import './components/checkout/CartTotal';
import './components/checkout/CartContents';
import './components/checkout/CartProduct';
import './components/checkout/OrderForm';
import './components/checkout/RegisterForm';
import './components/checkout/SuccessDialog';
import './components/login/LoginForm';
import './components/shared/TeamForm';
import './components/shared/FeedbackForm';
// import './components/account/ButtonAccount';
// import './components/account/AccountHostory';
import './components/account/AccountProfile';
import './components/account/AccountAddress';
import './components/account/AddressRow';
import {generateGradient} from './helpers';

// window.addEventListener('scroll', () => {
//   document.body.style.setProperty('--scroll-progress', window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
// }, false);
//

(function () {



  const url = new URL(location);

  const isHome = () => {
    if (url.search.includes('route=home') || url.pathname === '/') {
      return true
    }
    return false;
  }

  window.dialogLogin = document.querySelector('login-form');

  let isCollapsed = false;
  const pageBannerMenu = document.querySelector('.page__banner');
  let bannerHeight = 0;

  const resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      if (entry.borderBoxSize) {
        bannerHeight = entry.borderBoxSize[0].blockSize;
      }
    }
  });

  if (pageBannerMenu) {
    resizeObserver.observe(pageBannerMenu);
  }

  window.addEventListener(
    'scroll',
    () => {
      const mouseScrollableListElement = document.querySelector(
        'mouse-scrollable-list'
      );
      const categoryNavigationListElement = document.querySelector(
        '.category-navigation__list'
      );
      const cuisineItemElements = document.querySelectorAll('.cuisine-item');

      if (!isCollapsed && window.scrollY > bannerHeight + 20) {
        mouseScrollableListElement.scrollDisabled = false;
        categoryNavigationListElement.classList.remove(
          'category-navigation__list--multiline'
        );


        if (isHome()){
          cuisineItemElements.forEach(cuisineItemElement =>
              cuisineItemElement.classList.remove('cuisine-item--large')
          );
        }



        isCollapsed = true;
      } else if (isCollapsed && window.scrollY <= 0) {
        mouseScrollableListElement.scrollDisabled = true;
        categoryNavigationListElement.classList.add(
          'category-navigation__list--multiline'
        );


        if (isHome()){
          cuisineItemElements.forEach(cuisineItemElement =>
              cuisineItemElement.classList.add('cuisine-item--large')
          );
        }


        isCollapsed = false;
      }
    },
    false
  );
})();

try {
  const elementHeightObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      if (
        entry.borderBoxSize &&
        entry.target.hasAttribute('data-height-variable')
      ) {
        const borderBoxSize = entry.borderBoxSize[0];

        document.documentElement.style.setProperty(
          `--${entry.target.dataset.heightVariable}`,
          `${borderBoxSize.blockSize}px`
        );
      }
    }
  });

  const setHeightVariableElements = document.querySelectorAll(
    '[data-height-variable]'
  );

  if (setHeightVariableElements.length) {
    setHeightVariableElements.forEach(setHeightVariableElement =>
      elementHeightObserver.observe(setHeightVariableElement)
    );
  }
} catch (error) {
  console.error('Установка переменных высоты элементов');
}

swiperRegister();

const bannerCarouselElements = document.querySelectorAll('.banner__carousel');

if (bannerCarouselElements.length) {
  try {
    bannerCarouselElements.forEach(bannerCarouselElement => {
      Object.assign(bannerCarouselElement, {
        slidesPerView: 1,
        loop: true,
        speed: 1000,
        // autoplay: {
        //   delay: 5000,
        //   pauseOnMouseEnter: true
        // },
        keyboard: true,
        grabCursor: true,
        // effect: 'cube',
        // cubeEffect: {
        //   shadow: false,
        //   slideShadows: false
        // },
        navigation: {
          enabled: true,
          prevEl: bannerCarouselElement.parentElement.querySelector(
            '.banner__navigation-button--prev'
          ),
          nextEl: bannerCarouselElement.parentElement.querySelector(
            '.banner__navigation-button--next'
          )
        },
        pagination: {
          enabled: true,
          el:bannerCarouselElement.parentElement.querySelector('.banner__pagination'),
          clickable: true
        },
        a11y: {
          enabled: true,
          prevSlideMessage: 'К предыдущему слайду',
          nextSlideMessage: 'К следующему слайду',
          paginationBulletMessage: 'Перейти к {{index}} слайду'
        }
      });

      bannerCarouselElement.initialize();
    });
  } catch (error) {
    console.error(error);
  }
}


const featuredCartElements = document.querySelectorAll('.product-list__carousel')
if (featuredCartElements.length) {
  try {
    featuredCartElements.forEach(featuredCartElement => {
      Object.assign(featuredCartElement, {
        slidesPerView: 2,
        loop: false,
        speed: 1000,
        autoplay: {
          delay: 5000,
          pauseOnMouseEnter: true
        },
        keyboard: true,
        grabCursor: true,
        effect: 'cube',
        cubeEffect: {
          shadow: false,
          slideShadows: false
        },
        navigation: {
          enabled: true,
          prevEl: bannerCarouselElement.parentElement.querySelector(
            '.banner__navigation-button--prev'
          ),
          nextEl: bannerCarouselElement.parentElement.querySelector(
            '.banner__navigation-button--next'
          )
        },
        pagination: {
          enabled: true,
          clickable: true
        },
        a11y: {
          enabled: true,
          prevSlideMessage: 'К предыдущему слайду',
          nextSlideMessage: 'К следующему слайду',
          paginationBulletMessage: 'Перейти к {{index}} слайду'
        }
      });

      bannerCarouselElement.initialize();
    });
  } catch (error) {
    console.error(error);
  }
}
document.querySelectorAll('.running-text').forEach(element => {
  const timeline = new ViewTimeline({
    subject: element
  });

  element.querySelectorAll('.running-text__line').forEach((line, index) => {
    line.animate(
      {
        transform:
          index % 2 === 0
            ? ['translateX(0)', 'translateX(-50%)']
            : ['translateX(-50%)', 'translateX(0)']
      },
      {
        fill: 'both',
        timeline: timeline,
        rangeStart: 'entry 0%',
        rangeEnd: 'cover 100%'
      }
    );
  });
});

const productLinkElements = document.querySelectorAll('.product-item__link');
const imagesProduct = document.querySelectorAll('.product-thumb__image-container');
const handlerOpenProduct = async () => {
  event.preventDefault();
  const dialogElement = document.querySelector('.dialog--product');
  if (!dialogElement) return false;
  const response = await axios.get(
      `/index.php?route=product/product_card/info&product_id=${
          event.target
              .closest('.product-item')
              .querySelector('[name="product_id"]').value
      }`
  );
  if (response.data) {
    dialogElement.innerHTML = response.data.data.product_card;
    dialogElement.showModal();

    dialogElement.addEventListener('click', event => {
      const dialogDimensions = dialogElement.getBoundingClientRect();

      if (
          event.clientX < dialogDimensions.left ||
          event.clientX > dialogDimensions.right ||
          event.clientY < dialogDimensions.top ||
          event.clientY > dialogDimensions.bottom ||
          event.target.closest('.dialog__close-button')
      ) {
        dialogElement.close();
      }
    });
  }
}
if (productLinkElements) {
  productLinkElements.forEach(productElement => {
    productElement.addEventListener('click', handlerOpenProduct);
  });
}

if (imagesProduct) {
  imagesProduct.forEach(productElement => {
    productElement.addEventListener('click', handlerOpenProduct);
  });
}

const accountLinkElement = document.querySelectorAll('.icon-badge__link--login');
if (accountLinkElement) {
  accountLinkElement.forEach((item) => {
    item.addEventListener('click', async event => {
      event.preventDefault();
      if ( window.dialogLogin){

      }else{
         window.dialogLogin = document.querySelector('login-form')
      }

      if (  window.dialogLogin){
        window.dialogLogin.openDialog()
      }
      // dialogLogin.dispatchEvent(new Event('myOpen'))
      return

      // const dialogElement = document.querySelector('#loginForm');

      // if (!dialogElement) return false;
      //
      // const gradient = generateGradient();
      //
      // let styleElement = document.createElement('style');
      //
      // styleElement.textContent = `
      // .dialog::backdrop {
      //   background-image: ${gradient};
      // }
    // `;
    //
    //   dialogElement.append(styleElement);
    //   dialogElement.showModal();
    //
    //   dialogElement.addEventListener('click', event => {
    //     const dialogDimensions = dialogElement.getBoundingClientRect();
    //
    //     if (
    //       event.clientX < dialogDimensions.left ||
    //       event.clientX > dialogDimensions.right ||
    //       event.clientY < dialogDimensions.top ||
    //       event.clientY > dialogDimensions.bottom ||
    //       event.target.closest('.dialog__close-button')
    //     ) {
    //       styleElement.remove();
    //       dialogElement.close();
    //     }
    //   });
    });





  })
}

const hamburgerBtn = document.querySelector('.main-menu__hamburger');
const hamburgerDialog = document.querySelector('.hamburger');
if (hamburgerBtn) {
  if (hamburgerDialog) {
    let styleElement
    hamburgerBtn.addEventListener('click', () => {
      const gradient = generateGradient();

      styleElement = document.createElement('style');

      styleElement.textContent = `
      .hamburger::backdrop {
        background-image: ${gradient};
      }
    `;

      hamburgerDialog.append(styleElement);
      hamburgerDialog.showModal();
      toggleTwoClasses(hamburgerDialog, 'is-visible', 'is-hidden', 500);
      hamburgerDialog.addEventListener('click', event => {
        const dialogDimensions = hamburgerDialog.getBoundingClientRect();
        if (
          event.clientX < dialogDimensions.left ||
          event.clientX > dialogDimensions.right ||
          event.clientY < dialogDimensions.top ||
          event.clientY > dialogDimensions.bottom ||
          event.target.closest('.dialog__close-button')
        ) {
          // styleElement.remove();
          hamburgerDialog.close();
          styleElement.remove();
          toggleTwoClasses(hamburgerDialog, 'is-visible', 'is-hidden', 300);
        }
      });
    });


    const links = hamburgerDialog.querySelectorAll('.hamburger__categories a')
    links.forEach(item => {
      item.addEventListener('click', event => {
        event.preventDefault()
        hamburgerDialog.close();
        styleElement.remove();
        toggleTwoClasses(hamburgerDialog, 'is-visible', 'is-hidden', 300);
        setTimeout(() => {
          location = event.target.href
        }, 1000)
      })
    })
  }
}

function toggleTwoClasses(element, first, second, timeOfAnimation) {
  if (!element.classList.contains(first)) {
    element.classList.add(first);
    element.classList.remove(second);
  } else {
    element.classList.add(second);
    window.setTimeout(function () {
      element.classList.remove(first);
    }, timeOfAnimation);
  }
}
window.scrollSmoothTo = function (anchor,offset=0){
  const anchorNew =anchor.replace("anchor_",'');
  const target = document.querySelector(`${anchorNew}`);
  const top = target.getBoundingClientRect().top + window.pageYOffset - offset ;
  window.scrollTo({
    top,
    left: 0,
    behavior: "smooth",
  })
  history.replaceState({}, document.title, window.location.href.split('#')[0]);

}
window.addEventListener('load',function (){
  // получили очищенное имя якоря- #anchor_search превращается в #search
 if (document.location.hash!==''){
   scrollSmoothTo(document.location.hash,400)
 }
})


window.copyToBuffer=(self)=>{
  const alert =  self.querySelector('.clipBoardSuccess');
  console.log()
  const text = self.parentElement.querySelector("a").innerText;
  navigator.clipboard.writeText(text).then(
      () => {
        alert.classList.add('open')
        setTimeout(()=>{
          alert.classList.remove('open')
        },1500)
      },
      (data) => {
        console.log('Ошибка копирования')
      }
  );

}





