import axios from 'axios';
import {suggestion} from "../../helpers";

customElements.define(
	'address-row',
	class extends HTMLElement {
		#initialized = false;
		#addressId;
		#form;
		#submit;
		#cancel;
		#remove;
		#edit;
		#fields = {};
		#addressString;
		#streetSuggestion=null;
		#timoutSuggestion=null;
		#suggestionList = [];
		connectedCallback() {
			// this.attachShadow({mode: 'open'});
			if (!this.#initialized) {
				this.#initialize();
			}
			this.#initialized = true;
		}
		#initialize = () => {

			this.#streetSuggestion = this.querySelector('.shipping_street__suggest')
			console.log(	this.#streetSuggestion )
			this.#addressId = this.getAttribute('addressId');
			this.#form = this.querySelector('form');
			this.#submit = this.#form.querySelector('[type=submit]');
			this.#cancel = this.#form.querySelector('[type=button]');
			this.#remove = this.querySelector('.addressesItem__remove');
			this.#edit = this.querySelector('.addressesItem__edit');
			this.#addressString = this.querySelector('.addressesItem__address');
			this.#fields.street = this.#form.querySelector('[name=street]');
			this.#fields.house = this.#form.querySelector('[name=house]');
			this.#fields.apartment = this.#form.querySelector('[name=apartment]');
			this.#fields.entrance = this.#form.querySelector('[name=entrance]');
			this.#fields.floor = this.#form.querySelector('[name=floor]');
			this.#fields.name = this.#form.querySelector('[name=name]');

			this.#subscribeEvents();
		};
		#subscribeEvents = () => {
			if (this.#edit) this.#edit.addEventListener('click', this.#handleClickEdit);
			if (this.#remove) this.#remove.addEventListener('click', this.#handleClickRemove);
			this.#cancel.addEventListener('click', this.#handleClickCancel);
			this.#form.addEventListener('submit', this.#handleFromSubmit);
			this.#fields.street.addEventListener('input',this.#handleStreetInput)
		};

		disconnectedCallback() {
			if (this.#edit) this.#edit.removeEventListener('click', this.#handleClickEdit);
			this.#cancel.removeEventListener('click', this.#handleClickCancel);
			this.#form.removeEventListener('submit', this.#handleFromSubmit);
			if (this.#remove) this.#remove.removeEvent('click', this.#handleClickRemove);
			this.#fields.street.removeEventListener('input',this.#handleStreetInput)
		}

		openForm = () => {
			this.#form.hidden = false;
			if (this.#remove) this.#remove.hidden = true;
			if (this.#edit) this.#edit.hidden = true;
			this.#addressString.hidden = true;
		};
		#outsideClick = (event)=>{
			console.log(event)
			if(!(event.target ===this.#streetSuggestion||this.#streetSuggestion.contains(event.target))){
				this.#streetSuggestion.hidden = true;
				this.#unSetClickOutsideSuggestion();
			}
		}
		#handleStreetInput = async event=>{

			clearTimeout(this.#timoutSuggestion)
			this.#timoutSuggestion =  setTimeout(async()=>{
				const params = {
					'count': 5,
					// "locations_geo": [{
					// 	"lat": coords[0],
					// 	"lon": coords[1],
					// 	"radius_meters": 3000
					// }],
					"from_bound": { "value": "city" },
					"to_bound": { "value": "street" },
					'query': event.target.value
				}
				const result  =await suggestion(params);
				this.#clearEventSuggestion()
				result?.map(res=>{
					const li = document.createElement('li');
					li.innerText =res.value
					li.classList.add('shipping_street__suggestItem')
					li.addEventListener('click',this.#handleClickSuggestionItem)
					this.#streetSuggestion.append(li)
					this.#suggestionList.push(li)
				});
				if(result?.length){
					this.#streetSuggestion.hidden=false
					this.#setClickOutsideSuggestion();
				}else{
					this.#streetSuggestion.hidden=true
					this.#unSetClickOutsideSuggestion();
				}
			},300);



		}
		#handleClickSuggestionItem = (event)=>{
			this.#streetSuggestion.hidden=true
			this.#fields.street.value = event.target.innerText
			this.#unSetClickOutsideSuggestion();

		}
		#clearEventSuggestion =()=>{
			this.#suggestionList.map((item)=>{
				item.remove()
			})
		}
		#setClickOutsideSuggestion = ()=>{
			document.addEventListener('click',this.#outsideClick)
		}
		#unSetClickOutsideSuggestion = ()=>{
			document.removeEventListener('click',this.#outsideClick)
		}
		#handleClickEdit = () => {
			this.openForm();
		};
		#handleFromSubmit = async event => {
			event.preventDefault();
			this.#submit.disabled = true;
			try {
				const formData = new FormData(this.#form);
				const response = await axios.post(this.#form.action, formData);
				if (response.data.errors  && Object.keys(response.data.errors).length) {
					for (const key in response.data.errors) {
						if (key === 'warning') {

							document.querySelector('alert-dialog').showWarning(response.data.errors[key])
						}
						this.#fields[key].input.classList.add('text-input--secondary');
						this.#fields[key].label.classList.add('form__label--accent');
						this.#fields[key].err.innerText = response.data.errors[key];
					}
				} else {
					location.reload();
				}
			} catch (e) {
				console.log(e)
			}
		};
		#handleClickCancel = () => {
			this.#form.hidden = true;
			if (this.#remove) this.#remove.hidden = false;
			if (this.#edit) this.#edit.hidden = false;
			this.#addressString.hidden = false;
		};

		#handelClickAddAddressBtn = event => {
			// if (this.#formAddress.btnAdd.hidden === true) {
			// 	this.#formAddress.btnAdd.hidden = false;
			// } else {
			// 	this.#formAddress.btnAdd.hidden = true;
			// }
			// if (this.#formAddress.form.hidden === false) {
			// 	this.#formAddress.form.hidden = true;
			// } else {
			// 	this.#formAddress.form.hidden = false;
			// }
		};

		#handleClickRemove = async event => {

			const formData = new FormData();
			formData.append('address_id',this.#addressId)
			const response = await axios.post('/index.php?route=account/address/delete', formData);
			if (response.data.errors) {
				for (const key in response.data.errors) {
					if (key === 'warning') {
                        document.querySelector('alert-dialog').showWarning(response.data.errors[key])

                    }
				}
			} else {
				location.reload();
			}

			//удавление адреса
		};
		#saveAddress = event => {
		};

	}
);
