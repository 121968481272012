import axios from 'axios';

customElements.define(
	'discount-selector',
	class extends HTMLElement {
		#initialized = false;
		#formElement = null;

		// static get observedAttributes() {
		// 	return ['code'];
		// }
    //
		// get code() {
		// 	return this.getAttribute('code');
		// }
    //
		// set code(newValue) {
		// 	if (newValue) {
		// 		this.setAttribute('code', newValue);
		// 	} else {
		// 		this.removeAttribute('code');
		// 	}
		// }

		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();

				this.#formElement.addEventListener('submit', this.#handleSubmit);
				this.#formElement.addEventListener('change', this.#handleChange);
			}

			this.#initialized = true;
		}

		disconnectedCallback() {
			this.#formElement.removeEventListener('submit', this.#handleSubmit);
			this.#formElement.removeEventListener('change', this.#handleChange);
		}

		// attributeChangedCallback(attributeName, oldValue, newValue) {
		// 	if (!this.#initialized || oldValue === newValue) {
		// 		return;
		// 	}
    //
		// 	switch (attributeName) {
		// 		case 'code': {
		// 			this.#updateFormAction();
		// 			this.#updateInputValue();
		// 			this.#handleChange();
		// 			this.#updateButtonText();
    //
		// 			break;
		// 		}
    //
		// 		default: {
		// 			console.warn(
		// 				'Необработанное изменение атрибута',
		// 				attributeName,
		// 				oldValue,
		// 				newValue
		// 			);
    //
		// 			break;
		// 		}
		// 	}
		// }

		#initialize() {
			this.#formElement = this.querySelector('form');

			this.#showModule();
		}

    #handleSubmit = async event => {
      event.preventDefault();
    };

		#handleChange = async (event) => {
      this.#showModule();

      try {
        const formData = new FormData(this.#formElement);

        await axios.post(this.#formElement.action, formData);

        this.#fireEvent();
      } catch (error) {
        console.error(error);
      }
		};

    #showModule() {
      const checkedInputElement = this.#formElement.querySelector('input:checked');

      if (checkedInputElement){
          const activeModuleId = checkedInputElement ? checkedInputElement.getAttribute('aria-controls') : '';
          const moduleElements = this.querySelectorAll('.discount-selector__module-container');
          if (moduleElements.length) {
              moduleElements.forEach(moduleElement => moduleElement.hidden = true);
          }
          const activeModuleElement = this.querySelector(`#${activeModuleId}`);

          if (activeModuleElement) {
              activeModuleElement.hidden = false;
          }
      }

    }

    #fireEvent() {
      const event = new CustomEvent('totalsChange', {
        bubbles: true,
        cancelable: true,
        composed: true
      });

      this.dispatchEvent(event);
    }
	}
);
