customElements.define(
	'button-next',
	class extends HTMLElement {
		#initialized = false;
		#ul=null;
		connectedCallback() {
			if (!this.#initialized) {
				this.#initialize();
			}
			this.#initialized = true;
		}
		disconnectedCallback() {

		}
		#handelClick = ()=>{
			{ this.sideScroll(this.#ul, 'right', 20, (150+28), 10) }

		}
		 sideScroll(element,direction,speed,distance,step){
			let scrollAmount = 0;
			let slideTimer = setInterval(function(){
				if(direction == 'left'){
					element.scrollLeft -= step;
				} else {
					element.scrollLeft += step;
				}
				scrollAmount += step;
				if(scrollAmount >= distance){
					window.clearInterval(slideTimer);
				}
			}, speed);
		}
		#initialize() {
			this.#ul=this.parentNode.querySelector('ul');
			this.addEventListener('click',this.#handelClick)



		}
	}
);
