import axios from "axios";

const containerTemplate = document.createElement('template');
containerTemplate.innerHTML = `
  <dl class="order-totals"></dl>
`;

const primaryRowTemplate = document.createElement('template');
primaryRowTemplate.innerHTML = `
  <div class="order-totals__group">
    <dt class="order-totals__definition"></dt>
    <dd class="order-totals__description"></dd>
  </div>
`;

const secondaryRowTemplate = document.createElement('template');
secondaryRowTemplate.innerHTML = `
  <div class="order-totals__group order-totals__group--space-between">
    <dt class="order-totals__definition order-totals__definition--without-colon"></dt>
    <dd class="order-totals__description order-totals__description--without-underline"></dd>
  </div>
`;

const rowTemplates = {
  primary: primaryRowTemplate,
  secondary: secondaryRowTemplate,
}

const variants = ['primary', 'secondary'];

customElements.define(
  'order-totals',
  class extends HTMLElement {
    #initialized = false;
    #descriptionListElement = null;

    static get observedAttributes() {
      return ['totals', 'variant'];
    }

    get totals() {
      try {
        return JSON.parse(this.getAttribute('totals'));
      } catch (error) {
        return [];
      }
    }

    set totals(newValue) {
      if (newValue) {
        this.setAttribute('totals', JSON.stringify(newValue));
      } else {
        this.removeAttribute('totals');
      }
    }

    get variant() {
      return variants.includes(this.getAttribute('variant')) ? this.getAttribute('variant') : variants[0];
    }

    set variant(newValue) {
      if (newValue) {
        this.setAttribute('variant', JSON.stringify(newValue));
      } else {
        this.removeAttribute('variant');
      }
    }

    connectedCallback() {
      if (!this.#initialized) {
        this.#initialize();
      }

      this.#initialized = true;
    }

    disconnectedCallback() {
    }

    attributeChangedCallback(attributeName, oldValue, newValue) {
      if (!this.#initialized || oldValue === newValue) {
        return;
      }

      switch (attributeName) {
        case 'totals': {
          this.#renderRows();
          break;
        }

        case 'variant': {
          this.#renderRows();
          this.#toggleContainerClass();

          break;
        }

        default: {
          console.warn(
            'Необработанное изменение атрибута',
            attributeName,
            oldValue,
            newValue
          );

          break;
        }
      }
    }

    #initialize() {
      this.appendChild(containerTemplate.content.cloneNode(true));
      this.#descriptionListElement = this.querySelector('dl');
      this.#toggleContainerClass();
      this.#renderRows();
    }

    #toggleContainerClass() {
      if (this.variant === 'secondary') {
        this.#descriptionListElement.classList.add('order-totals--secondary');
      } else {
        this.#descriptionListElement.classList.remove('order-totals--secondary');
      }
    }

    #renderRows() {
      this.#descriptionListElement.innerHTML = '';

      if (!this.totals.length) {
        return false;
      }

      const items = this.totals.map(total => {
        const row = rowTemplates[this.variant].content.cloneNode(true);
        const cells = row.querySelectorAll('.order-totals__group > *');
        cells[0].textContent = total.title;
        cells[1].textContent = parseInt(total.text) === 0 ? 'бесплатно' : total.text;

        return row;
      });

      this.#descriptionListElement.append(...items);
    }
  }
);

const update = async () => {
  const orderTotalsElements = document.querySelectorAll('order-totals');

  if (!orderTotalsElements.length) {
    return;
  }

  const response = await axios.get(
    '/index.php?route=checkout/order_details/totals'
  );

  if (response.data.data.totals) {
    orderTotalsElements.forEach(orderTotalsElement => {
      orderTotalsElement.totals = response.data.data.totals;
    });
  }
}

try {
  document.addEventListener('totalsChange', update);
  document.addEventListener('cartChange', update);
} catch (error) {
  console.error(error);
}
